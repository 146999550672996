import { AGENT_STATUS } from '../types';

export default (state, action) => {
  switch (action.type) {
    case AGENT_STATUS:
      return {
        agentState: action.payload.agentState,
        agentStatus: action.payload.agentStatus,
    };
    
    default:
      return state;
  }
};

// TODO fix this typescript error
const env = process.env.REACT_APP_ENV || '';
//const env = 'staging';

const CognitoConfigs = {
  dev: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_hDKAqoSHU',
      userPoolWebClientId: 'ht5av1c5qqoj5f5q6uam8e5g4',
      identityPoolId: 'us-east-1:60885621-ca83-4579-97c0-796f8bde41b2',
    },
    API: {
      endpoints: [
        {
          name: 'Cannovate',
          endpoint:
            'https://brq1hlf5m8.execute-api.us-east-1.amazonaws.com/dev',
        },
      ],
    },
  },
  staging: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_ICAbB0BAj',
      userPoolWebClientId: 'aqv2sak12s5shubtu052djkv0',
      identityPoolId: 'us-east-1:5bc3a4ae-a2d6-4c8e-905d-0b914e72bd2a',
    },
    API: {
      endpoints: [
        {
          name: 'Cannovate',
          endpoint:
            'https://owhr86ig97.execute-api.us-east-1.amazonaws.com/staging',
        },
      ],
    },
  },

  prod: {
    Auth: {
      region: 'us-east-1',
      userPoolId: 'us-east-1_oen75SnYm',
      userPoolWebClientId: 'v2ekk35datanllml4s7h5i27e',
      identityPoolId: 'us-east-1:56027f56-44ca-4d41-a79d-be8a047c8b6b',
    },
    API: {
      endpoints: [
        {
          name: 'Cannovate',
          endpoint:
            'https://n1cou5uc66.execute-api.us-east-1.amazonaws.com/prod',
        },
      ],
    },
  },
};

const CognitoConfig = CognitoConfigs[env];

export default CognitoConfig;

import setAuthToken from '../../config/axios';

import {
  GET_ME_SUCCESS,
  GET_ME_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  RESET_PASSWORD_SUCCESS,
  MESSAGE,
} from '../types';

let token;
let refreshToken;
let resetPasswordRequired;

export default (state, action) => {
  switch (action.type) {
    case GET_ME_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        user: action.payload,
      };
    case LOGIN_SUCCESS:
      resetPasswordRequired =
        action.payload.challengeName === 'NEW_PASSWORD_REQUIRED';
      if (!resetPasswordRequired) {
        token = action.payload.signInUserSession.idToken.jwtToken;
        refreshToken = action.payload.signInUserSession.refreshToken.token;
        localStorage.setItem('token', token);
        localStorage.setItem('refreshToken', refreshToken);
        setAuthToken(token);
      }
      return {
        ...state,
        token: token || '',
        isAuthenticated: true,
        loading: false,
        resetPasswordRequired,
      };
    case RESET_PASSWORD_SUCCESS:
      token = action.payload.signInUserSession.idToken.jwtToken;
      refreshToken = action.payload.signInUserSession.refreshToken.token;
      localStorage.setItem('refreshToken', refreshToken);
      localStorage.setItem('token', token);
      setAuthToken(token);
      return {
        ...state,
        token: token || '',
        isAuthenticated: true,
        resetPasswordRequired: false,
      };
    case GET_ME_FAIL:
    case LOGIN_FAIL:
      return {
        ...state,
        errorMsg: action.payload,
        loading : false
      }
    case LOGOUT:
      localStorage.removeItem('token');
      localStorage.removeItem('refreshToken');
      return {
        ...state,
        token: null,
        refreshToken: null,
        isAuthenticated: false,
        loading: false,
        user: null,
      };
    case MESSAGE:
      return {
        ...state,
        messages: [action.payload, ...state.messages],
      };
    default:
      return state;
  }
};

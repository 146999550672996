import React, { useContext, useState, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import {
  faUser,
  faThLarge,
  faUsers,
  faCannabis,
  faFileContract,
  faSignOutAlt,
  faPowerOff,
  faHistory,
  faHome,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  AuthContext,
  ContactContext,
  SidePanelContext,
} from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import { themeContext } from '../../context/theme/themeContext';
import { sidePanelContext as _sidePanelContext } from '../../context/sidePanel/sidePanelContext';
import { Spinner } from 'react-bootstrap';
import '../../assets/css/switch.scss';
import '../../assets/css/sidepanel.scss';
import { printDesignation } from '../../models/utils';
import { CaseSkill } from '../../models/cases';

const SidePanel = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { isAuthenticated, resetPasswordRequired, user } = authContext;
  const [agentLoggedStatus, setagentLoggedStatus] = useState('');
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const [agentStatusSwitch, setAgentStatusSwitch] = useState(false);
  const sidePanelContext: SidePanelContext = useContext(_sidePanelContext);
  const contactContext: ContactContext = useContext(_contactContext);
  const renderPhone = contactContext.renderPhone!;
  const { renderCall } = contactContext;
  const { agentStatus } = sidePanelContext;
  const logout = authContext.logout || (() => {});

  const handleReload = () => {
    if (renderCall) {
      window.location.reload();
    }
  };

  const handleAgentOnlineChange = () => {
    if (!renderCall) {
      setDisplaySpinner(true);
      renderPhone();
    }
  };

  useEffect(() => {
    if (agentStatus === 'Offline') {
      setagentLoggedStatus('Available');
    } else {
      setagentLoggedStatus(agentStatus!);
    }

    if (agentStatus) {
      setDisplaySpinner(false);
    }
  }, [agentStatus]);

  return isAuthenticated && !resetPasswordRequired ? (
    <aside className="dashboard-sidepanel flex-column justify-content-between">
      <div>
        <section className="dashboard-sidepanel--user">
          {user &&
            (user!.groups.includes(CaseSkill.CSR) ||
              user!.groups.includes(CaseSkill.CD)) && (
              <div className="d-flex flex-column pr-3 pl-3">
                <div className="agent-online-status d-flex justify-content-between mb-3">
                  <h6 className="sidepanel-agentstatus">Agent status: </h6>
                  <div className="d-flex flex-column">
                    <div
                      className={`badge text-wrap mb-2 badge-${
                        agentStatusSwitch || renderCall ? 'success' : 'danger'
                      }`}
                    >
                      {agentStatusSwitch || renderCall ? 'Online' : 'Offline'}
                    </div>

                    <label
                      htmlFor="online"
                      className={`ml-0 mb-0 switch agent-online-${agentStatusSwitch}`}
                      onClick={
                        renderCall
                          ? handleReload
                          : () => setAgentStatusSwitch(!agentStatusSwitch)
                      }
                    >
                      <input
                        type="checkbox"
                        checked={agentStatusSwitch || renderCall}
                        className={`ml-0 mb-0 switch agent-online-${agentStatusSwitch}`}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="justify-content-between d-flex align-items-center">
                  <h6 className="sidepanel-agentstatus">AWS Connect</h6>
                  <h6
                    className="sidepanel-agentstatus"
                    onClick={
                      renderCall ? handleReload : handleAgentOnlineChange
                    }
                  >
                    <button
                      style={{
                        background: 'transparent',
                        outline: 'transparent',
                        border: 'transparent',
                      }}
                      onClick={handleReload}
                    >
                      <FontAwesomeIcon
                        icon={faPowerOff}
                        style={{ width: '3rem', height: '1.25rem' }}
                        className={`agent-online-${renderCall}`}
                      />
                    </button>
                  </h6>
                </div>
              </div>
            )}
          {!!user && user.groups && (
            <div className="dashboard-sidepanel--user-image">
              <img
                src="/images/cannovate-profile.png"
                style={{
                  height: '5rem',
                  width: '5rem',
                  color: '#ccc',
                  border: '1px solid #75D317',
                }}
                alt="display picture"
              />
            </div>
          )}
          <div>
            {/* Only For Dev Env */}
            {displaySpinner ? (
              <Spinner animation="border" />
            ) : agentLoggedStatus ? (
              <span className="sidepanel-agentstatus">
                Call Status : {agentLoggedStatus}
              </span>
            ) : null}
            {/* Only For Dev Env */}
          </div>
          <div style={{ marginBottom: '15px' }}>
            {!!user && user.agentname && (
              <span className="sidepanel-agentname">
                {/* {printName} */}
                {user!.agentname}
              </span>
            )}
            {!!user && user.groups && (
              <>
                <span className="font-weight-bold">{user!.email}</span>
                <br />
                <span className="sidepanel-agentroll">
                  {printDesignation(user!.groups)}
                </span>
              </>
            )}
          </div>
        </section>

        <section>
          {user &&
            (user!.groups.includes(CaseSkill.CSR) ||
              user!.groups.includes(CaseSkill.CD)) && (
              <ul className="sidepanel-nav">
                <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="/dashboard"
                  >
                    <FontAwesomeIcon icon={faThLarge} />
                    Queue
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="/cases"
                  >
                    <FontAwesomeIcon icon={faUser} />
                    Cases
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="/patients"
                  >
                    <FontAwesomeIcon icon={faUsers} />
                    Patients
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="/dispensaries"
                  >
                    <FontAwesomeIcon icon={faCannabis} />
                    Dispensaries
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="/knowledge"
                  >
                    <FontAwesomeIcon icon={faFileContract} />
                    Knowledge
                  </NavLink>
                </li>
                {/* <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="/new-case"
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                    New Case
                  </NavLink>
                </li> */}
                <li>
                  <p onClick={() => logout()}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    Logout
                  </p>
                </li>
              </ul>
            )}
          {user &&
            (user!.groups.includes(CaseSkill.DR) ||
              user!.groups.includes(CaseSkill.Admin)) && (
              <ul className="sidepanel-nav">
                <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="reservations"
                  >
                    <FontAwesomeIcon icon={faHome} />
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    activeClassName="activeNav"
                    // activeStyle={{ backgroundColor: '#f7f8fa', color: 'black' }}
                    to="past-reservations"
                  >
                    <FontAwesomeIcon icon={faHistory} />
                    Past Reservations
                  </NavLink>
                </li>
                <li>
                  <p onClick={() => logout()}>
                    <FontAwesomeIcon icon={faSignOutAlt} />
                    Logout
                  </p>
                </li>
              </ul>
            )}
        </section>
      </div>
      <section className="sidepanel-logo">
        <img src="/images/cannovate-logo.png" />
      </section>
    </aside>
  ) : null;
};

export default SidePanel;

import React, { useContext, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import cleanDeep from 'clean-deep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPhoneAlt,
  faEnvelope,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faExternalLinkAlt,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { AuthContext, ContactContext, Order } from '../../models/common';
import { getDispensaryColor } from '../../models/dispensaries';
import {
  getFormattedDateTime,
  getContactName,
  printStatus,
  printName,
} from '../../models/utils';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import { searchCases } from '../../services/case.service';
import { FormControl, Grid } from '@material-ui/core';
import {
  Spinner,
  Dropdown,
  InputGroup,
  Form,
  Button,
  Modal,
  Accordion,
  Card,
} from 'react-bootstrap';
import { caseContext as _caseContext } from '../../context/case/caseContext';
import { authContext as _authContext } from '../../context/auth/authContext';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import {
  Case,
  CaseContext,
  CaseCreate,
  CaseSkill,
  CaseState,
  InboundChannel,
  OutboundChannel,
} from '../../models/cases';
import { dispensaries } from '../../config/dispenseries';
import '../../assets/css/cases.scss';
import { toast } from 'react-toastify';
import { UserSummary } from '../../models/users';
import { Patient } from '../../models/patients';
import { Agent } from 'https';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#858b8d',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

type CaseListProps = {
  dispensaryId?: number;
  patientId?: number;
  patient?: Patient;
  caseId?: number;
  from: string;
};

const CaseList = React.memo<CaseListProps>((props) => {
  const caseContext: CaseContext = useContext(_caseContext);
  const authContext: AuthContext = useContext(_authContext);
  const contactContext: ContactContext = useContext(_contactContext);
  const { createCase } = caseContext;

  // console.log('Auth Context', authContext.user);

  const classes = useStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [cases, setCases] = React.useState();
  const [counts, setCounts] = React.useState(0);
  const [showNewCaseOptions, setShowNewCaseOptions] = React.useState(false);

  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState('CASEID');
  const [caseDetails, setCaseDetails] = React.useState<Case>();

  const [initValues] = React.useState({
    dispensaryId: '',
    status: '',
    patientFName: '',
    patientLName: '',
    inboundPhoneNumber: '',
    caseId: '',
  });
  const [inputs, setInputs] = React.useState(initValues);
  const [dispensaryName, setDispensaryName] = React.useState('Dispensary');
  const [ddStatus, setDDStatus] = React.useState('Status');
  const [params, setParams] = React.useState<any>({
    dispensaryId: props.dispensaryId,
    patientId: props.patientId,
    caseId: props.caseId,
  });

  const statusByNames = ['Resolved', 'Resolved', 'In Progress', 'Pending'];

  const handleNewCase2 = async (outboundChannel: OutboundChannel) => {
    const { patient } = props;
    const { initCall, initEmail, renderCall } = contactContext;
    const { user } = authContext;

    if (outboundChannel == OutboundChannel.PHONE) {
      if (!renderCall) {
        console.log('NO RENDER CALL');
        toast.error('You need to be online to make a phone call!');
        return;
      }
      if (patient!.phoneNumber == undefined) {
        toast.warning('Patient does not have a phone number');
        return;
      }
    } else {
      if (patient!.emailAddress == undefined) {
        toast.warning('Patient does not have an email address');
        return;
      }
    }

    if (
      createCase !== undefined &&
      user != undefined &&
      initCall != undefined &&
      initEmail != undefined
    ) {
      console.log('User', user);
      const userSummary: UserSummary = {
        id: user.id || '0',
        name: user.agentname,
        email: 'admin.prod@cannovate.io',
        phoneNumber: user.phoneNumber || '+14453003677',
        groups: user.groups,
      };

      const caseInstance: CaseCreate = {
        inboundChannel: InboundChannel.EMAIL,
        inboundPhoneNumber: patient!.phoneNumber, //'+12407207518',
        inboundEmailAddress: patient!.emailAddress,
        assignedCSR: userSummary,
        skill: CaseSkill.CSR,
        state: CaseState.OUTBOUND,
      };

      try {
        const newCase = await createCase(params.dispensaryId, caseInstance);

        if (outboundChannel == OutboundChannel.EMAIL) {
          newCase.outboundChannel = InboundChannel.EMAIL;
          newCase.logs = [
            {
              note: 'Email case created by CSR',
              skill: 'CSR',
              status: 'IN_PROGRESS',
              logType: 'SYSTEM',
              createdUser: user,
              createdUserId: user.id,
              createdDateTime: new Date(),
            },
          ];
          await initEmail(newCase, user);
        } else {
          initCall(newCase, user, 'outbound');
        }
      } catch (e) {
        console.error('Error creating a new case', e);
      }
    }

    console.log('Patient Details', patient);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangInput = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDropDowns = (target, value) => {
    if (target === 'dispensary') {
      setInputs((inputs) => ({ ...inputs, dispensaryId: value }));
      setDispensaryName(
        dispensaries.find((dispensery) => {
          return dispensery.id === Number(value);
        }).name,
      );
    } else if (target === 'Status') {
      setInputs((inputs) => ({ ...inputs, status: value }));
      if (value === 'CLOSED') {
        setDDStatus(statusByNames[0]);
      } else if (value === 'COMPLETED') {
        setDDStatus(statusByNames[1]);
      } else if (value === 'IN_PROGRESS') {
        setDDStatus(statusByNames[2]);
      } else if (value === 'PENDING') {
        setDDStatus(statusByNames[3]);
      }
    }
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    setCases(undefined);
    setParams(inputs);
    setPage(0);
  };

  const handleReset = (event: any) => {
    if (event) event.preventDefault();
    setCases(undefined);
    setPage(0);
    setInputs(initValues);
    setParams(initValues);
    setDispensaryName('Dispensary');
    setDDStatus('Status');
  };

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>,
  ) => {
    if (event) event.preventDefault();
    const isAsc = orderBy === property && order === 'asc';
    setCases(undefined);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const getCases = async () => {
    const res = await searchCases({
      ...cleanDeep(params),
      offset: page * rowsPerPage,
      limit: rowsPerPage,
      order: order,
      orderBy: orderBy,
      getCount: true,
      patientId: props.patientId,
    });

    if (res.id) {
      // Search result for case ID based search
      setCounts(1);
      setCases([res]);
      return;
    }
    setCounts(res.count);
    setCases(res.records);
  };

  useEffect(() => {
    if (!cases) getCases();
  }, [page, rowsPerPage, order, params, orderBy, props]); // eslint-disable-line react-hooks/exhaustive-deps

  const CaseModal = (props) => {
    console.log('Case Data >>', caseDetails);
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          {/* <Modal.Title id="contained-modal-title-vcenter">
        {(caseDetails != undefined)? `Case Number : ${caseDetails.caseNumber}` : null}
        </Modal.Title> */}
          {caseDetails !== undefined && (
            <div
              id="case-detail-header-details"
              style={{
                display: 'grid',
                gridTemplateColumns: '1fr 1.5fr 1fr',
                gridGap: '15px',
              }}
            >
              <div>
                Number: &nbsp;
                <span>{caseDetails!.caseNumber}</span>
              </div>
              <div>
                Status: &nbsp;
                <span>{printStatus(caseDetails!)}</span>
              </div>
              <div>
                Type: &nbsp;
                <span style={{ whiteSpace: 'nowrap' }}>{`${
                  caseDetails!.state
                } ${caseDetails!.inboundChannel!}`}</span>
              </div>
              <div>
                Skill: &nbsp;
                <span>{caseDetails!.skill}</span>
              </div>
              <div>
                Created: &nbsp;
                <span>
                  {getFormattedDateTime(caseDetails!.createdDateTime, false)}
                </span>
              </div>
              <div>
                Dispensary: &nbsp;
                <span>{caseDetails!.dispensary.name}</span>
              </div>
            </div>
          )}
        </Modal.Header>
        <Modal.Body>
          <h6>Logs</h6>
          <Accordion>
            {caseDetails && caseDetails.logs
              ? caseDetails.logs.map((log, index) => (
                  <Card key={index}>
                    <Card.Header>
                      <Accordion.Toggle
                        as={Card.Header}
                        variant="link"
                        eventKey={Number(index).toString()}
                        style={{
                          background: 'none',
                          border: 'none',
                          cursor: 'pointer',
                        }}
                      >
                        {log.note}
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse
                      eventKey={Number(index).toString()}
                      style={{ padding: '10px' }}
                    >
                      <Card.Body>
                        {log.createdDateTime &&
                          `Time Stamp : ${new Date(log.createdDateTime)}`}
                        <br />
                        {log.logType && `Type : ${log.logType}`}
                        <br />
                        {log.createdUser &&
                          `Agent : ${printName({ logs: [log] }) ||
                            'Unassigned'}`}
                        <br />
                        {log.note && `Note : ${log.note}`}
                        <br />
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                ))
              : null}
          </Accordion>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={props.onHide}
            style={{ backgroundColor: '#75c699', borderColor: '#75c699' }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const casesTable = () => (
    <>
      <CaseModal
        show={caseDetails !== undefined}
        onHide={() => setCaseDetails(undefined)}
      />
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                key="CASEID"
                align="center"
                style={{ width: '40px' }}
                sortDirection={orderBy === 'CASEID' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'CASEID'}
                  direction={orderBy === 'CASEID' ? order : 'desc'}
                  onClick={createSortHandler('CASEID')}
                >
                  CASE
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'CREATEDDATE'}
                align="center"
                style={{ width: '160px' }}
                sortDirection={orderBy === 'CREATEDDATE' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'CREATEDDATE'}
                  direction={orderBy === 'CREATEDDATE' ? order : 'desc'}
                  onClick={createSortHandler('CREATEDDATE')}
                >
                  CREATED DATE
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'STATE'}
                align="center"
                style={{ width: '40px' }}
                sortDirection={orderBy === 'STATE' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'STATE'}
                  direction={orderBy === 'STATE' ? order : 'desc'}
                  onClick={createSortHandler('STATE')}
                >
                  TYPE
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              {props.from !== 'CallDetails' && (
                <StyledTableCell
                  key={'CONTACT'}
                  align="center"
                  style={{ width: '150px' }}
                >
                  CONTACT
                </StyledTableCell>
              )}
              {props.from !== 'CallDetails' && (
                <StyledTableCell
                  key={'PATIENTFIRSTNAME'}
                  align="center"
                  style={{ width: '150px' }}
                  sortDirection={orderBy === 'PATIENTFIRSTNAME' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'PATIENTFIRSTNAME'}
                    direction={orderBy === 'PATIENTFIRSTNAME' ? order : 'desc'}
                    onClick={createSortHandler('PATIENTFIRSTNAME')}
                  >
                    PATIENT
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  </TableSortLabel>
                </StyledTableCell>
              )}
              {props.from! in ['dispensary', 'CallDetails'] && (
                <StyledTableCell
                  key={'DISPENSARYNAME'}
                  align="center"
                  style={{ width: '160px' }}
                  sortDirection={orderBy === 'DISPENSARYNAME' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'DISPENSARYNAME'}
                    direction={orderBy === 'DISPENSARYNAME' ? order : 'desc'}
                    onClick={createSortHandler('DISPENSARYNAME')}
                  >
                    DISPENSARY
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  </TableSortLabel>
                </StyledTableCell>
              )}
              {props.from === 'CallDetails' ? (
                <StyledTableCell
                  key={'AGENT'}
                  align="center"
                  style={{ width: '40px' }}
                  sortDirection={orderBy === 'AGENT' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'AGENT'}
                    direction={orderBy === 'AGENT' ? order : 'desc'}
                    onClick={createSortHandler('AGENT')}
                  >
                    AGENT
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  </TableSortLabel>
                </StyledTableCell>
              ) : (
                <StyledTableCell
                  key={'SKILL'}
                  align="center"
                  style={{ width: '40px' }}
                  sortDirection={orderBy === 'SKILL' ? order : false}
                >
                  <TableSortLabel
                    active={orderBy === 'SKILL'}
                    direction={orderBy === 'SKILL' ? order : 'desc'}
                    onClick={createSortHandler('SKILL')}
                  >
                    SKILL
                    <span className={classes.visuallyHidden}>
                      {order === 'desc'
                        ? 'sorted descending'
                        : 'sorted ascending'}
                    </span>
                  </TableSortLabel>
                </StyledTableCell>
              )}
              <StyledTableCell
                key={'STATUS'}
                align="center"
                style={{ width: '50px' }}
                sortDirection={orderBy === 'STATUS' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'STATUS'}
                  direction={orderBy === 'STATUS' ? order : 'desc'}
                  onClick={createSortHandler('STATUS')}
                >
                  STATUS
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: '80px' }}
              ></StyledTableCell>
            </TableRow>
          </TableHead>
          {cases && cases!.length > 0 && (
            <TableBody>
              {cases!.map((data: any) => (
                <StyledTableRow key={data.caseNumber}>
                  <StyledTableCell component="th" scope="row" align="center">
                    {data.caseNumber}
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span className="case-list-date">
                      {getFormattedDateTime(data.createdDateTime)}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span className="no-wrap">
                      {data.inboundChannel === 'PHONE' ? (
                        <FontAwesomeIcon icon={faPhoneAlt} />
                      ) : (
                        <FontAwesomeIcon icon={faEnvelope} />
                      )}
                      &nbsp;
                      {data.state === 'INBOUND' ? (
                        <FontAwesomeIcon icon={faLongArrowAltLeft} />
                      ) : (
                        <FontAwesomeIcon icon={faLongArrowAltRight} />
                      )}
                    </span>
                  </StyledTableCell>
                  {props.from !== 'CallDetails' && (
                    <StyledTableCell align="center">
                      {data.inboundChannel === 'PHONE'
                        ? data.inboundPhoneNumber
                        : data.inboundEmailAddress}
                    </StyledTableCell>
                  )}
                  {props.from !== 'CallDetails' && (
                    <StyledTableCell align="center">
                      <span className="cases-table-data--name">
                        <Link
                          to={`/patients/${data.patient.id}/dispensaries/${data.dispensary.id}`}
                        >
                          {getContactName(data)}
                        </Link>
                      </span>
                    </StyledTableCell>
                  )}
                  {props.from! in ['dispensary', 'CallDetails'] && (
                    <StyledTableCell align="center">
                      <span
                        className={`cases-table-data--dispensary background-color-${getDispensaryColor(
                          data.dispensary,
                        )}`}
                      >
                        <Link to={`/dispensaries/${data.dispensary.id}`}>
                          {data.dispensary.name.slice(0, 12)}
                        </Link>
                      </span>
                    </StyledTableCell>
                  )}
                  {props.from === 'CallDetails' ? (
                    <StyledTableCell align="center">
                      {printName(data)}
                    </StyledTableCell>
                  ) : (
                    <StyledTableCell align="center">
                      {data.skill}
                    </StyledTableCell>
                  )}
                  <StyledTableCell align="center">
                    <span className="cases-table-data--status">
                      {data.status && printStatus(data)}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {props.from === 'CallDetails' ? (
                      <span
                        onClick={() => {
                          setCaseDetails(data);
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faExternalLinkAlt}
                          style={{ color: '19FF59', cursor: 'pointer' }}
                        />
                      </span>
                    ) : (
                      <Link
                        className={`link-color-${getDispensaryColor(
                          data.dispensary,
                        )}`}
                        to={`/dispensaries/${data.dispensary.id}/cases/${data.caseNumber}`}
                      >
                        <FontAwesomeIcon icon={faExternalLinkAlt} />
                      </Link>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {cases ? (
        cases!.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
            count={counts}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          <div className="empty-table-message">No cases to display</div>
        )
      ) : (
        <div className="table-loading-container cases-margin-left-false">
          <Spinner animation="border" />
        </div>
      )}
      <div>&nbsp;</div>
    </>
  );

  return (
    <>
      <Paper>
        <form
          className={classes.root}
          noValidate
          autoComplete="off"
          style={{ marginTop: '1rem !important' }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <FormControl variant="filled">
                <Dropdown
                  id="dispensaryId1"
                  onSelect={(e) => {
                    handleDropDowns('dispensary', e);
                  }}
                >
                  <Dropdown.Toggle
                    variant="outline-info"
                    id="dropdown-basic"
                    style={{ width: '8rem' }}
                  >
                    {dispensaryName}
                    {` `}
                  </Dropdown.Toggle>

                  {dispensaries && (
                    <Dropdown.Menu>
                      {dispensaries.map((dispensery) => {
                        return (
                          <Dropdown.Item
                            key={dispensery.name}
                            eventKey={dispensery.id}
                          >
                            {dispensery.name}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  )}
                </Dropdown>
              </FormControl>
            </Grid>
            <Grid item>
              <FormControl variant="filled">
                <Dropdown
                  onSelect={(e) => {
                    handleDropDowns('Status', e);
                  }}
                >
                  <Dropdown.Toggle
                    variant="outline-info"
                    id="dropdown-basic"
                    style={{ width: '8rem' }}
                  >
                    {ddStatus}
                    {` `}
                  </Dropdown.Toggle>

                  <Dropdown.Menu>
                    {/* <Dropdown.Item eventKey="CLOSED">Closed</Dropdown.Item> */}
                    <Dropdown.Item eventKey="COMPLETED">Resolved</Dropdown.Item>
                    <Dropdown.Item eventKey="IN_PROGRESS">
                      In Progress
                    </Dropdown.Item>
                    <Dropdown.Item eventKey="PENDING">
                      Pending action
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </FormControl>
            </Grid>
            <Grid item className="alignItem">
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">
                    {/* First Name  */}
                    <FontAwesomeIcon icon={faSearch} size="sm" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl>
                  <Form.Control
                    type="text"
                    id="inputGroup-sizing-sm"
                    placeholder="Case ID"
                    aria-label="Case ID"
                    aria-describedby="basic-addon3"
                    value={inputs.caseId}
                    name={'caseId'}
                    onChange={handleChangInput}
                    style={{ width: '5rem' }}
                  />
                </FormControl>
              </InputGroup>
            </Grid>
            <Grid item className="alignItem">
              <InputGroup className="mb-3" size="sm">
                <InputGroup.Prepend>
                  <InputGroup.Text id="basic-addon3">
                    {/* First Name  */}
                    <FontAwesomeIcon icon={faSearch} size="sm" />
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <FormControl>
                  <Form.Control
                    type="text"
                    id="inputGroup-sizing-sm"
                    placeholder="Patient phone"
                    aria-label="Patient phone"
                    aria-describedby="basic-addon3"
                    value={inputs.inboundPhoneNumber}
                    name={'inboundPhoneNumber'}
                    onChange={handleChangInput}
                    style={{ width: '8rem' }}
                  />
                </FormControl>
              </InputGroup>
            </Grid>
            <Button
              variant="outline-info"
              onClick={handleSubmit}
              size="sm"
              className="btnCustom"
            >
              Search
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              variant="outline-dark"
              onClick={handleReset}
              size="sm"
              className="btnCustom"
            >
              RESET
            </Button>
          </Grid>
        </form>
        {['patient'].includes(props.from) && (
          <div className="d-flex flex-1 flex-row-reverse">
            <Button
              variant={showNewCaseOptions ? 'danger' : 'primary'}
              className="mr-4"
              onClick={() => setShowNewCaseOptions((isShown) => !isShown)}
            >
              {showNewCaseOptions ? 'Cancel' : 'New Case'}
            </Button>
            {showNewCaseOptions ? (
              <>
                <Button
                  onClick={() => handleNewCase2(OutboundChannel.EMAIL)}
                  className="ml-1 mr-1"
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </Button>
                <Button onClick={() => handleNewCase2(OutboundChannel.PHONE)}>
                  <FontAwesomeIcon icon={faPhoneAlt} />
                </Button>
              </>
            ) : null}
          </div>
        )}
      </Paper>
      <div className="cases" style={{ padding: 0 }}>
        {casesTable()}
      </div>
    </>
  );
});

export default CaseList;

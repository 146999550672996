/* eslint-disable  space-in-parens */
import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Redirect } from 'react-router-dom';
import {
  Form,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Card,
  Accordion,
  Container,
  Row,
  Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faTrash,
  faEnvelopeOpenText,
} from '@fortawesome/free-solid-svg-icons';
import { Case, CaseLog, InboundChannel } from '../../models/cases';
import {
  searchCases,
  saveCase,
  getDownload,
  deleteCase,
} from '../../services/case.service';
import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import { getDuplicates } from '../../services/case.service';
import { printStatus } from '../../models/utils';
import '../../assets/css/checkbox.scss';
import '../../assets/css/contact-detail.scss';

type CaseContactInfoProps = {
  caseId: number;
};

const EmailPreviewModal = (props) => {
  const getFormattedDateTime = (date) => {
    const d = new Date(date);
    return (
      <span>
        <span>
          {`${d.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
          })}`}
        </span>
        &nbsp;&nbsp;
        <span style={{ whiteSpace: 'nowrap' }}>{`${d.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}`}</span>
      </span>
    );
  };

  const getInitials = (email) => {
    var initials = '';
    if (email && email.patientName) {
      email.patientName.split(' ').map((x) => {
        initials += x[0] || '';
      });
      console.log('PatientName', email.patientName);
    } else if (email && email.patientEmailAddress) {
      initials = email.patientEmailAddress.slice(0, 2);
    }
    return initials.toUpperCase();
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ display: 'grid' }}>
        <div className="email-icon" style={{ backgroundColor: 'grey' }}>
          {getInitials(props.email)}
        </div>
        <div className="email-sender-name">
          {props.email && props.email.patientName
            ? props.email.patientName
            : 'N/A'}
        </div>
        <div
          style={{ color: '#a0a0a0', fontSize: '0.9rem', fontWeight: 'bold' }}
        >
          {props.email && props.email.date
            ? getFormattedDateTime(props.email.date)
            : ''}
        </div>
        <div className="email-sender-emailId">
          {props.email && props.email.patientEmailAddress
            ? props.email.patientEmailAddress
            : 'N/A'}
        </div>
      </Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        {props.email && props.email.subject
          ? props.email.subject
          : 'Error loading email subject'}
      </Modal.Title>
      <Modal.Body>
        <div
          dangerouslySetInnerHTML={{
            __html: props.email && props.email.html ? props.email.html : null,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

const CaseContactInfo = React.memo<CaseContactInfoProps>((props) => {
  const authContext: AuthContext = useContext(_authContext);

  const [emailDetails, setEmailDetails] = React.useState();
  const [viewEmail, setViewEmail] = React.useState(false);
  const [duplicates, setDuplicates] = useState<any>('');
  const [contactCase, setContactCase] = useState<Case>();
  const [validated, setValidated] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [audioFile, setAudioFile] = useState('');
  const [showConfirm, setShowConfirm] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [caseLog, setCaseLog] = useState<CaseLog>({
    category: 'GENERAL_INQ',
    note: '',
    followUp: false,
    transfer: false,
    skill: undefined,
    status: undefined,
    state: undefined,
    inboundChannel: undefined,
  });
  const [category, setCategory] = useState('GENERAL_INQ');
  const { user } = authContext;

  const getCase = async () => {
    const res = await searchCases({
      caseId: props.caseId,
    });
    setContactCase(res);
    setCategory(res.category ? res.category : 'GENERAL_INQ');
    setCaseLog({
      ...caseLog,
      inboundChannel: res.inboundChannel,
    });
  };

  useEffect(() => {
    getCase();
    getDuplicates(props.caseId)
      .then((duplicates) => setDuplicates(duplicates))
      .catch((error) => {
        console.log(error);
      });
  }, [props.caseId]);

  useEffect(() => {
    console.log('contactCase', contactCase);
  }, [contactCase]);

  const getFormattedDateTime = useCallback((date) => {
    const d = new Date(date);
    return (
      <span>
        <span>
          {`${d.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
          })}`}
        </span>
        &nbsp;&nbsp;
        <span>{`${d.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}`}</span>
      </span>
    );
  }, []);

  const getFormattedDate = useCallback((date) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    });
  }, []);

  const getCategoryName = (category) => {
    switch (category) {
      case 'ADVERSE_EVENT':
        return 'Adverse Event';
      case 'INVENTORY':
        return 'Inventory';
      case 'PRODUCT_INFO':
        return 'Product Information';
      case 'MEDICAL_INQ':
        return 'Medical Inquiry';
      case 'GENERAL_INQ':
        return 'General Inquiry';
    }
  };

  const handleSubmit = async (event) => {
    try {
      const form = event.currentTarget;
      event.preventDefault();
      event.stopPropagation();
      if (form.checkValidity() === true) {
        const res = await saveCase(
          contactCase!.dispensary.id,
          contactCase!.caseNumber,
          { ...caseLog, logType: 'USER' },
        );
        setContactCase(res);
        setCategory(res!.category ? res!.category : 'GENERAL_INQ');
        setCaseLog({ ...caseLog, note: ' ' });
      }
      setValidated(true);
      setRedirect(true);
    } catch (e) {
      console.error('case redirection error', e);
    }
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/cases" />;
    }
    return null;
  };

  const getDuration = (contactCase) => {
    if (contactCase && contactCase.logs) {
      const logs = contactCase.logs;
      var startTime = 0;
      var endTime = 0;
      for (var i in logs) {
        const note = logs[i].note;
        if (new RegExp('W*(picked up by)W*').test(note))
          startTime = new Date(logs[i].createdDateTime || '0').getTime();
        if (new RegExp('W*(ended by/from)W*').test(note))
          endTime = new Date(logs[i].createdDateTime || '0').getTime();
      }
      if (endTime - startTime > 0) {
        return `${Math.floor((endTime - startTime) / 1000)} Seconds`;
      }
    }
    return 'N/A';
  };

  const transformCapitalization = (word) =>
    word &&
    word
      .toLowerCase()
      .charAt(0)
      .toUpperCase() + word.toLowerCase().slice(1);

  const conversions: any = React.useMemo(
    () =>
      contactCase && contactCase.logs && contactCase.logs.length > 0
        ? contactCase!.logs.filter(
            (log) => !log.logType || log.logType !== 'SYSTEM',
          )
        : [],
    [contactCase],
  );
  const histories: any = React.useMemo(
    () =>
      contactCase && contactCase.logs && contactCase.logs.length > 0
        ? contactCase!.logs.filter(
            (log) => log.logType && log.logType === 'SYSTEM',
          )
        : [],
    [contactCase],
  );

  return (
    <>
      <EmailPreviewModal
        show={viewEmail}
        onHide={() => setViewEmail(false)}
        email={emailDetails}
      />
      <div className="case-detail-container--background">
        {contactCase ? (
          <Form noValidate validated={validated} onSubmit={handleSubmit}>
            <div id="case-detail-container">
              <div id="case-detail-header">
                <div id="case-detail-header-title">{'Case Details'}</div>
                <div id="case-detail-header-details">
                  <div>
                    Case ID: &nbsp;
                    <span>{contactCase!.caseNumber}</span>
                  </div>
                  <div>
                    Status: &nbsp;
                    <span>{printStatus(contactCase!)}</span>
                  </div>
                  <div>
                    Type: &nbsp;
                    <span
                      style={{ whiteSpace: 'nowrap' }}
                    >{`${transformCapitalization(
                      contactCase!.state,
                    )} ${transformCapitalization(
                      contactCase!.inboundChannel!,
                    )}`}</span>
                  </div>
                  <div>
                    Skill: &nbsp;
                    <span>{contactCase!.skill}</span>
                  </div>
                  <div>
                    Created: &nbsp;
                    <span>
                      {getFormattedDate(contactCase!.createdDateTime)}
                    </span>
                  </div>
                  <div>
                    Dispensary: &nbsp;
                    <span>{contactCase!.dispensary.name}</span>
                  </div>
                  <div>
                    Duration: &nbsp;
                    <span>{getDuration(contactCase!)}</span>
                  </div>
                  <div>
                    Duplicates: &nbsp;
                    <span>{duplicates}</span>
                  </div>
                </div>
              </div>

              <div id="case-detail-body">
                <div id="case-log-checkboxes"></div>
                <Row className="w-100 mr-0 ml-0 pt-4">
                  <Col style={{ flexGrow: 1 }} className="pr-0 pl-0">
                    <Card className="w-100 h-100 mt-0">
                      <Card.Header>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                          Notes
                          <div className="d-flex flex-row align-items-center">
                            <span className="case-log-title mr-2">
                              Category:
                            </span>
                            <Form.Group>
                              <Form.Control
                                as="select"
                                style={{ height: '1.5rem', padding: 'unset' }}
                                className="mr-sm-2"
                                onChange={(e: any) => {
                                  setCaseLog({
                                    ...caseLog,
                                    category: e.target.value,
                                  });
                                  setCategory(e.target.value);
                                }}
                                value={category || 'GENERAL_INQ'}
                              >
                                <option value="ADVERSE_EVENT">
                                  Adverse Event
                                </option>
                                <option value="INVENTORY">Inventory</option>
                                <option value="PRODUCT_INFO">
                                  Product Information
                                </option>
                                <option value="MEDICAL_INQ">
                                  Medical Inquiries
                                </option>
                                <option value="GENERAL_INQ">
                                  General Inquiries
                                </option>
                                <option value="INELIGIBLE">
                                  Ineligible Patient
                                </option>
                              </Form.Control>
                              <Form.Control.Feedback type="invalid">
                                Please select a category for this case
                              </Form.Control.Feedback>
                            </Form.Group>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <Form.Group
                          id="case-notes"
                          className="p-0"
                          style={{ height: '100%' }}
                        >
                          <Form.Control
                            // TODO check if required
                            required
                            type="text"
                            as="textarea"
                            id="case-note-text-area"
                            placeholder={
                              contactCase!.inboundChannel ===
                                InboundChannel.PHONE && 'Notes on this case..'
                            }
                            rows={10}
                            onChange={(e: any) =>
                              setCaseLog({ ...caseLog, note: e.target.value })
                            }
                            value={caseLog.note}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please input a note for this case
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Card.Body>
                      <Card.Footer className="p-0 text-right">
                        <button
                          type="submit"
                          className="case-button green-case-button"
                        >
                          Save Case
                        </button>
                      </Card.Footer>
                    </Card>
                  </Col>
                </Row>
                {/* Log Section  */}
                {contactCase && contactCase.logs && contactCase.logs.length ? (
                  <div className="w-100">
                    <Row>
                      <Col>
                        <Card className="card-style h-100 mt-0">
                          <Card.Header className="card-header owncss">
                            Log History Details
                          </Card.Header>
                          {histories && histories.length > 0 ? (
                            <Accordion className="overflow-auto">
                              {histories.map((log, index) => (
                                <Card
                                  key={`${log.createdDateTime}`}
                                  className="mt-0"
                                >
                                  <Accordion.Toggle
                                    as={Card.Header}
                                    eventKey={index.toString()}
                                    className="title-accordian owncsstxt"
                                  >
                                    <div className="p-3">
                                      <p
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignContent: 'center',
                                          alignItems: 'center',
                                          float: 'left',
                                        }}
                                      >
                                        {getFormattedDateTime(
                                          log.createdDateTime,
                                        )}{' '}
                                        :{' '}
                                        {log.note.includes('Call')
                                          ? `(Call) : `
                                          : log.note.includes('Email')
                                          ? `(Email) : `
                                          : `(Note) : `}
                                        {`${log.note}`}
                                      </p>
                                      <span
                                        className="ownspan btn"
                                        onClick={() => {
                                          setEmailDetails({
                                            ...log.email,
                                            patientName: `${
                                              contactCase.patient
                                                ? contactCase.patient.firstName
                                                : ''
                                            } ${
                                              contactCase.patient
                                                ? contactCase.patient.lastName
                                                : ''
                                            }`,
                                          });
                                          setViewEmail(true);
                                        }}
                                      >
                                        {log.email && (
                                          <FontAwesomeIcon
                                            icon={faEnvelopeOpenText}
                                          />
                                        )}
                                      </span>
                                    </div>
                                  </Accordion.Toggle>
                                  <Accordion.Collapse
                                    className="content-css"
                                    eventKey={index.toString()}
                                  >
                                    <Card.Body
                                      className={
                                        log.note.includes('Email')
                                          ? `email-modal`
                                          : `call-body`
                                      }
                                    >
                                      {/* <pre className="align-center"> */}
                                      {log.note.includes('Email') &&
                                      log.email ? (
                                        <Container>
                                          <Card>
                                            {log.email.subject ? (
                                              <Card.Header>
                                                {' '}
                                                <b>Subject : </b>
                                                {log.email.subject}{' '}
                                              </Card.Header>
                                            ) : (
                                              `No Log`
                                            )}
                                            {log.email.html ? (
                                              <Card.Body>
                                                {' '}
                                                <pre
                                                  style={{ padding: '1rem' }}
                                                >
                                                  {' '}
                                                  <div
                                                    dangerouslySetInnerHTML={{
                                                      __html: log.email.html,
                                                    }}
                                                  ></div>
                                                </pre>{' '}
                                              </Card.Body>
                                            ) : null}
                                          </Card>
                                        </Container>
                                      ) : log.note.includes('Call') ? (
                                        log.note
                                      ) : null}
                                      {log.callRecordingS3Location ? (
                                        <span style={{ fontSize: '14px' }}>
                                          {' '}
                                          <b>&nbsp; Audio</b>
                                        </span>
                                      ) : null}
                                      {log.callRecordingS3Location ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="call-recording">
                                              Play Recording
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            role="button"
                                            tabIndex={0}
                                            style={{
                                              cursor: 'pointer',
                                              marginLeft: '.5rem',
                                            }}
                                            onClick={async () => {
                                              const recording = await getDownload(
                                                log.callRecordingS3Location ||
                                                  '',
                                              );
                                              setAudioFile(
                                                recording.downloadUrl,
                                              );
                                            }}
                                            onKeyPress={async () => {
                                              const recording = await getDownload(
                                                log.callRecordingS3Location ||
                                                  '',
                                              );
                                              setAudioFile(
                                                recording.downloadUrl,
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMicrophone}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      ) : null}
                                      {log.callRecordingS3Location ? (
                                        <span style={{ fontSize: '14px' }}>
                                          {' '}
                                        </span>
                                      ) : null}
                                    </Card.Body>
                                  </Accordion.Collapse>
                                </Card>
                              ))}
                            </Accordion>
                          ) : (
                            'No Log For this Case'
                          )}
                        </Card>
                      </Col>

                      <Col>
                        <Card className="card-style h-100 mt-0">
                          <Card.Header className="card-header owncss">
                            Conversations
                          </Card.Header>
                          <div className="scroll-custom">
                            {conversions && conversions.length > 0 ? (
                              <Card.Body className="card-body scroll-custom">
                                {conversions &&
                                  conversions.reverse().map((log) => (
                                    <Card.Text
                                      className="card-text"
                                      key={`${log.createdDateTime}${log.note}`}
                                    >
                                      <b
                                        style={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                          alignContent: 'center',
                                          alignItems: 'center',
                                          float: 'left',
                                          whiteSpace: 'nowrap',
                                        }}
                                      >
                                        {getFormattedDateTime(
                                          log.createdDateTime,
                                        )}{' '}
                                        :{' '}
                                        {log.note.includes('Call')
                                          ? `(Call) : `
                                          : log.note.includes('Email')
                                          ? `(Email) : `
                                          : `(Note :: ${
                                              log.category
                                                ? getCategoryName(log.category)
                                                : ''
                                            } ) : `}
                                        {/* {(!log.note.includes('Call') && !log.note.includes('Email')) ? (log.category ?  getCategoryName(log.category) : null) : null}   */}
                                      </b>
                                      {` ${log.note}`}
                                      {log.callRecordingS3Location ? (
                                        <span style={{ fontSize: '14px' }}>
                                          {' '}
                                          &nbsp;Audio
                                        </span>
                                      ) : null}
                                      {log.callRecordingS3Location ? (
                                        <OverlayTrigger
                                          placement="top"
                                          overlay={
                                            <Tooltip id="call-recording">
                                              Play Recording
                                            </Tooltip>
                                          }
                                        >
                                          <span
                                            role="button"
                                            tabIndex={0}
                                            style={{
                                              cursor: 'pointer',
                                              marginLeft: '.5rem',
                                            }}
                                            onClick={async () => {
                                              const recording = await getDownload(
                                                log.callRecordingS3Location ||
                                                  '',
                                              );
                                              setAudioFile(
                                                recording.downloadUrl,
                                              );
                                            }}
                                            onKeyPress={async () => {
                                              const recording = await getDownload(
                                                log.callRecordingS3Location ||
                                                  '',
                                              );
                                              setAudioFile(
                                                recording.downloadUrl,
                                              );
                                            }}
                                          >
                                            <FontAwesomeIcon
                                              icon={faMicrophone}
                                            />
                                          </span>
                                        </OverlayTrigger>
                                      ) : null}

                                      {log.callRecordingS3Location ? (
                                        <span style={{ fontSize: '14px' }}>
                                          {' '}
                                        </span>
                                      ) : null}
                                    </Card.Text>
                                  ))}
                              </Card.Body>
                            ) : (
                              'No History for Conversation'
                            )}
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  </div>
                ) : null}
              </div>

              <div id="case-detail-actions">
                {user && user!.groups[0] === 'CD' && (
                  <button
                    type="button"
                    className="delete-button"
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
              </div>
            </div>
          </Form>
        ) : null}
        <Modal
          size="sm"
          show={errorMessage.length > 0}
          onHide={() => setErrorMessage('')}
        >
          <Modal.Body>{errorMessage}</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => setErrorMessage('')}>OK</Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={audioFile.length > 0}
          onHide={() => setAudioFile('')}
          centered
        >
          <Modal.Body
            style={{
              display: 'flex',
              backgroundColor: '#000',
              justifyContent: 'center',
              paddingTop: '0',
            }}
          >
            <iframe title="audio-file" src={audioFile} />
          </Modal.Body>
          <Modal.Footer>
            <button
              className="case-button silver-case-button"
              onClick={() => setAudioFile('')}
              type="button"
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
        {renderRedirect()}
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Body>Are you sure you want to delete this case?</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="cases-button silver-cases-button"
              onClick={() => setShowConfirm(false)}
            >
              No
            </button>
            <button
              type="button"
              className="cases-button silver-cases-button"
              onClick={async () => {
                setShowConfirm(false);
                await deleteCase(
                  contactCase!.dispensary.id,
                  contactCase!.caseNumber,
                );
                setRedirect(true);
              }}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
});

export default CaseContactInfo;

import React, { useContext, useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import {
  Modal,
  Table,
  Badge,
  Dropdown,
  Toast,
  Card,
  Popover,
  OverlayTrigger,
} from 'react-bootstrap';
import { CaseContext, Case, InboundChannel } from '../../../models/cases';
import { getDispensaryColor } from '../../../models/dispensaries';
import {
  ContactContext,
  AuthContext,
  formatPhoneNumber,
  SidePanelContext,
} from '../../../models/common';
import { contactContext as _contactContext } from '../../../context/contact/contactContext';
import { caseContext as _caseContext } from '../../../context/case/caseContext';
import { authContext as _authContext } from '../../../context/auth/authContext';
import '../../../assets/css/callqueue.scss';
import { getTimerFromSeconds } from '../../../models/utils';
import { sidePanelContext as _sidePanelContext } from '../../../context/sidePanel/sidePanelContext';

const CallQueue = () => {
  const contactContext: ContactContext = useContext(_contactContext);
  const caseContext: CaseContext = useContext(_caseContext);
  const authContext: AuthContext = useContext(_authContext);
  const sidePanelContext: SidePanelContext = useContext(_sidePanelContext);
  const { agentStatus } = sidePanelContext;
  const { user } = authContext;

  const initCall = contactContext.initCall!;
  const initEmail = contactContext.initEmail!;
  const { pendingCases } = caseContext;
  const { renderCall, onGoingCall } = contactContext;

  const [modalShow, setModalShow] = useState(false);
  const [queueCase, setQueueCase] = useState(0);
  const [filterQueueCase, setFilterQueueCase] = useState('ALL');
  const [filterName, setFilterName] = useState('Filter');
  const [showA, setShowA] = useState(false);
  const [lastQueuedCase, setLastQueuedCase] = useState<Case | undefined>(
    undefined,
  );

  useEffect(() => {
    if (pendingCases && pendingCases.length > 0) {
      const lastQueuedCaseInstance = pendingCases[0];
      if (lastQueuedCaseInstance != undefined) {
        setLastQueuedCase((currentCase) => {
          if (currentCase == undefined) {
            return lastQueuedCaseInstance;
          } else {
            const currentId = currentCase.id;
            const newId = lastQueuedCaseInstance!.id;
            if (
              currentId != undefined &&
              newId != undefined &&
              newId > currentId
            ) {
              return lastQueuedCaseInstance;
            }
            return currentCase;
          }
        });
      }
    }
  }, [pendingCases, agentStatus]);

  useEffect(() => {
    if (lastQueuedCase != undefined) {
      var audio = new Audio('audio/phone-ring.mp3');
      if (lastQueuedCase.inboundChannel == InboundChannel.EMAIL) {
        audio = new Audio('audio/email-ring.mp3');
      }
      if (agentStatus == 'Available' || agentStatus == 'Offline') {
        audio.play();
      }
    }
  }, [lastQueuedCase]);

  const categoriesLabel = {
    ADVERSE_EVENT: 'Adverse Event',
    INVENTORY: 'Inventory',
    PRODUCT_INFO: 'Product Information',
    MEDICAL_INQ: 'Medical Inquiry',
    GENERAL_INQ: 'General Inquiry',
  };

  const popover = (title, text) => {
    return (
      <Popover id="popover-basic">
        <Popover.Title as="h3">{title}</Popover.Title>
        <Popover.Content>{text}</Popover.Content>
      </Popover>
    );
  };

  const toggleShowA = () => {
    setShowA(!showA);
    setTimeout(() => setShowA(!showA), 5000);
  };

  useEffect(() => {
    getCasesByFilter(filterQueueCase);
  }, [filterQueueCase]);

  const getInboundCallCase = () => {
    if (pendingCases) {
      let phoneCasesOnly = pendingCases.filter(
        (dataToFilter) => dataToFilter.inboundChannel === 'PHONE',
      );
      return phoneCasesOnly[0];
    }
  };

  useEffect(() => {
    if (pendingCases) {
      setQueueCase(pendingCases.length);
    } else {
      setQueueCase(0);
    }
  }, [pendingCases]);

  const handleQueueClick = (data, index) => {
    if (onGoingCall) {
      setShowA(true);
    } else {
      if (data.inboundChannel === 'PHONE') {
        if (!renderCall) {
          setModalShow(true);
        } else {
          initCall(getInboundCallCase(), user, 'inbound');
        }
      }
      if (data.inboundChannel === 'EMAIL') {
        initEmail(data, user);
      }
    }
  };

  const filterQueue = (evt) => {
    setFilterQueueCase(evt);
    setFilterName(evt);
  };

  const getContactName = (contact) => {
    if (contact.patient.firstName) {
      return `${contact.patient.firstName} ${contact.patient.lastName || ''}`;
    }
    if (contact.inboundChannel === 'EMAIL') {
      return contact.inboundEmailAddress;
    }
    if (contact.inboundChannel === 'PHONE') {
      return formatPhoneNumber(contact.inboundPhoneNumber);
    }
    return '';
  };

  const getCasesByFilter = (typeOfCase = 'ALL') => {
    if (pendingCases) {
      let finalCases;
      if (typeOfCase === 'ALL') {
        finalCases = pendingCases;
      } else {
        finalCases = pendingCases.filter(
          (data) => data.inboundChannel === typeOfCase,
        );
      }

      return finalCases.map((data, index) =>
        user &&
        ((!data.skill && user!.groups[0] === 'CSR') ||
          data.skill === user!.groups[0]) ? (
          <OverlayTrigger
            key={index}
            trigger={data.skill === 'CD' ? 'hover' : 'focus'}
            placement="bottom"
            overlay={popover(
              categoriesLabel[data ? data.category : null],
              data.logs ? data.logs[data.logs.length - 1].note : null,
            )}
          >
            <tr
              key={data.caseNumber}
              onClick={() => {
                handleQueueClick(data, index);
              }}
              style={{ cursor: 'pointer' }}
            >
              <td>{index + 1}.</td>
              <td>
                <span className="dashboard-queue--element-cell flex-center">
                  <FontAwesomeIcon
                    icon={
                      data.inboundChannel === 'PHONE' ? faPhone : faEnvelope
                    }
                  />
                </span>
              </td>
              <td>
                <p>{getContactName(data)}</p>
              </td>
              <td>
                <div
                  className={`dashboard-queue--element-cell dashboard-queue--element-name background-color-${getDispensaryColor(
                    data.dispensary,
                  )} `}
                >
                  <p>{data.dispensary.name.slice(0, 12)}</p>
                </div>
              </td>
              <td>Unassigned</td>
              <td>New</td>
              <td style={{ textAlign: 'center' }}>
                <p
                  className={
                    moment
                      .utc(data.createdDateTime.replace(/ /, 'T'))
                      .fromNow(false)
                      .includes('seconds')
                      ? `timeColor border-success`
                      : moment
                          .utc(data.createdDateTime.replace(/ /, 'T'))
                          .fromNow(false) === `a minute ago` &&
                        moment
                          .utc(data.createdDateTime.replace(/ /, 'T'))
                          .fromNow(false) <= `3 minutes ago`
                      ? `timeColor border-warning`
                      : moment
                          .utc(data.createdDateTime.replace(/ /, 'T'))
                          .fromNow(false)
                          .includes('minutes')
                      ? `timeColor border-danger`
                      : `timeColor border-danger`
                  }
                >
                  {/* {moment.utc(data.createdDateTime.replace(/ /, 'T'))} */}
                  <>
                    {getTimerFromSeconds(
                      (
                        (new Date().getTime() -
                          new Date(
                            data.createdDateTime.replace(/ /, 'T'),
                          ).getTime()) /
                          1000 +
                        new Date().getTimezoneOffset() * 60
                      ).toFixed(0),
                    )}
                  </>
                </p>
              </td>
            </tr>
          </OverlayTrigger>
        ) : (
          <li className="list-style-none" />
        ),
      );
    }
  };

  return (
    <div style={{ width: '100% !important' }} className="call-queue">
      {/* <div className="dashboard-queue-box"> */}
      <Toast
        className="outer-container--toast"
        style={{
          backgroundColor: 'orange',
          color: 'white',
          display: showA ? 'block' : 'none',
        }}
        show={showA}
        onClose={toggleShowA}
        delay={1500}
        autohide
      >
        <Toast.Body>Cant Pick up new call if already on going</Toast.Body>
      </Toast>

      <Card style={{ width: 'calc(100vw - 2rem)}' }}>
        <Card.Header style={{ textAlign: 'center' }}>
          <h5>
            <b>Queue</b>
            {queueCase > 0 ? (
              <>
                <Badge
                  pill
                  variant={
                    queueCase >= 20
                      ? `danger`
                      : queueCase > 5 && queueCase <= 19
                      ? `warning`
                      : queueCase <= 5
                      ? `success`
                      : `success`
                  }
                  style={{ marginTop: '-16px' }}
                >
                  {queueCase}
                </Badge>
                <div style={{ float: 'right' }}>
                  <Dropdown onSelect={filterQueue} drop="left">
                    <Dropdown.Toggle
                      variant="success"
                      id="dropdown-basic"
                      size="sm"
                    >
                      {filterName}
                      {` `}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item eventKey="ALL" selected>
                        All
                      </Dropdown.Item>
                      <Dropdown.Item eventKey="PHONE">Phone Only</Dropdown.Item>
                      <Dropdown.Item eventKey="EMAIL">Email Only</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </>
            ) : null}
          </h5>
        </Card.Header>
        <Card.Body className="overflow-auto">
          {pendingCases && pendingCases.length ? (
            <Table striped borderless hover>
              <thead>
                <tr>
                  <th style={{ width: '7%' }}>#</th>
                  <th style={{ width: '5%' }}>Type</th>
                  <th style={{ width: '20%' }}>Patient</th>
                  <th style={{ width: '20%' }}>Dispensary</th>
                  <th style={{ width: '15%' }}>Agent</th>
                  <th style={{ width: '13%' }}>Status</th>
                  <th style={{ width: '20%' }}>Time Duration</th>
                </tr>
              </thead>
              <tbody>{getCasesByFilter(filterQueueCase)}</tbody>
            </Table>
          ) : null}
          {pendingCases && !pendingCases.length ? (
            <div className="empty-table-message empty-table-message-margin">
              No cases to display
            </div>
          ) : null}
        </Card.Body>
      </Card>

      <Modal size="sm" show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Body>You must be online to answer a call!</Modal.Body>
        <Modal.Footer className="justify-center">
          <button
            type="button"
            className="case-button silver-case-button"
            onClick={() => setModalShow(false)}
          >
            OK
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CallQueue;

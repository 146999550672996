import {
  faThLarge,
  faUser,
  faUsers,
  faCannabis,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import '../../assets/css/bottom-navigation-bar.scss';
import { authContext as _authContext } from '../../context/auth/authContext';
import { AuthContext } from '../../models/common';

const BottomNavigationBar = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { isAuthenticated } = authContext;
  return isAuthenticated ? (
    <ul className="bottom-navigation-bar">
      <li>
        <NavLink activeClassName="activeNav" to="/dashboard">
          <FontAwesomeIcon icon={faThLarge} />
          Queue
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="activeNav" to="/cases">
          <FontAwesomeIcon icon={faUser} />
          Cases
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="activeNav" to="/patients">
          <FontAwesomeIcon icon={faUsers} />
          Patients
        </NavLink>
      </li>
      <li>
        <NavLink activeClassName="activeNav" to="/dispensaries">
          <FontAwesomeIcon icon={faCannabis} />
          Dispensaries
        </NavLink>
      </li>
    </ul>
  ) : null;
};

export default BottomNavigationBar;

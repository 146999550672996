import React, { useEffect, useState, useContext } from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { Modal, Form, Toast, Button } from 'react-bootstrap';
import { ContactContext, OutboundEmail } from '../../models/common';

import { contactContext as _contactContext } from '../../context/contact/contactContext';
import ContactDetail from '../Contact/ContactDetail';
import { isWhiteSpaceLike } from 'typescript';
import { CaseStatus } from '../../models/cases';
import '../../assets/css/email.scss';

const getFormattedDateTime = (date) => {
  const d = new Date(date);
  return (
    <span>
      <span>
        {`${d.toLocaleDateString('en-US', {
          day: 'numeric',
          month: 'numeric',
          year: '2-digit',
        })}`}
      </span>
      &nbsp;/&nbsp;
      <span>{`${d.toLocaleTimeString([], {
        hour: '2-digit',
        minute: '2-digit',
      })}`}</span>
    </span>
  );
};

function strip_html_tags(str) {
  if (str === null || str === '') return false;
  else str = str.toString();
  return str.replace(/<[^>]*>/g, '');
}

const getInitials = (email) => {
  var initials = '';
  if (email && email.patientName) {
    email.patientName.split(' ').map((x) => {
      initials += x[0];
    });
    console.log('PatientName', email.patientName);
  } else if (email && email.patientEmailAddress) {
    initials = email.patientEmailAddress.slice(0, 2);
  }
  return initials.toUpperCase();
};

const EmailPreviewModal = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header style={{ display: 'grid' }}>
        <div className="email-icon" style={{ backgroundColor: 'grey' }}>
          {getInitials(props.email)}
        </div>
        <div className="email-sender-name">
          {props.email && props.email.patientName
            ? props.email.patientName
            : 'N/A'}
        </div>
        <div
          style={{ color: '#a0a0a0', fontSize: '0.9rem', fontWeight: 'bold' }}
        >
          {props.email && props.email.date
            ? getFormattedDateTime(props.email.date)
            : ''}
        </div>
        <div className="email-sender-emailId">
          {props.email && props.email.patientEmailAddress
            ? props.email.patientEmailAddress
            : 'N/A'}
        </div>
      </Modal.Header>
      <Modal.Title id="contained-modal-title-vcenter">
        {props.email && props.email.subject
          ? props.email.subject
          : 'Error loading email subject'}
      </Modal.Title>
      <Modal.Body>
        <div
          dangerouslySetInnerHTML={{
            __html: props.email && props.email.html ? props.email.html : null,
          }}
        />
      </Modal.Body>
      <Modal.Footer>
        {/* <Button onClick={props.onHide}>Close</Button> */}
      </Modal.Footer>
    </Modal>
  );
};

const Email = () => {
  const [localShowEmail, setLocalShowEmail] = useState(true);
  const [showContactDetails, setLocalShowContactDetails] = useState(false);
  const [viewEmail, setViewEmail] = React.useState(false);
  const [emailDetails, setEmailDetails] = React.useState();

  const contactContext: ContactContext = useContext(_contactContext);
  const { contactCase, showEmail } = contactContext;
  const sendEmail = contactContext.sendEmail!;

  const [outboundEmail, setOutboundEmail] = useState<OutboundEmail>({
    subject:
      contactCase && contactCase.logs && contactCase.logs[1].email
        ? contactCase.logs[1].email.subject
        : '',
    bodyHtml: '',
  });

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleSend = () => {
    sendEmail(contactCase, outboundEmail);
    setShow(false);
  };
  const [showToast, setShowToast] = useState(false);

  const getPreviousEMails = (contactCase) => {
    let previousMails = '';
    if (contactCase.logs.length > 0)
      for (var i in contactCase.logs) {
        if (contactCase.logs[i].email && contactCase.logs[i].state)
          previousMails = contactCase.logs[i].email.textAsHtml;
      }
    return previousMails;
  };

  const isCaseHandeled = (emailCase) => {
    if (
      emailCase.status === CaseStatus.IN_PROGRESS ||
      emailCase.status === CaseStatus.PENDING
    ) {
      setShowToast(true);
      setTimeout(() => {
        setShowToast(false);
      }, 3000);
      return false;
    }
    return true;
  };

  useEffect(() => {
    if (showEmail) {
      setLocalShowEmail(true);
      setTimeout(() => setLocalShowContactDetails(true), 1000);
    } else {
      setLocalShowContactDetails(false);
      setLocalShowEmail(false);
    }
  }, [showEmail]);

  return (
    <>
      <EmailPreviewModal
        show={viewEmail}
        onHide={() => setViewEmail(false)}
        email={emailDetails}
      />
      <ReactCSSTransitionGroup
        transitionName="example"
        transitionAppear
        transitionAppearTimeout={500}
        transitionEnter={false}
        transitionLeave
        transitionLeaveTimeout={500}
      >
        {localShowEmail ? (
          <div className="email-sidebar-container">
            <ContactDetail showContactDetails={showContactDetails} />

            <button
              type="button"
              className="toggle-contact-detail-button"
              onClick={() => {
                if (isCaseHandeled(contactCase))
                  setLocalShowContactDetails(!showContactDetails);
              }}
            >
              {showContactDetails ? (
                <FontAwesomeIcon icon={faChevronRight} />
              ) : (
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
            </button>
            <div className="email-sidebar-header">
              <button
                type="submit"
                className="case-button silver-case-button"
                onClick={handleShow}
                style={{ color: 'white', background: 'green' }}
              >
                Reply Email
              </button>
            </div>

            <button
              type="button"
              className="toggle-contact-detail-button1"
              style={{ right: '1rem !important' }}
              onClick={() => {
                if (isCaseHandeled(contactCase)) setLocalShowEmail(false);
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <Toast
              className="outer-container--toast"
              style={{ backgroundColor: 'orange', color: 'white' }}
              show={showToast}
              delay={2000}
              autohide
            >
              <Toast.Body>
                Cant close panel if the case is still open.
              </Toast.Body>
            </Toast>
            {contactCase && (
              <div className="email-thread">
                {contactCase.logs.length > 1 ? (
                  contactCase.logs.map((log) => {
                    if (log.email) {
                      const i = log.email.textAsHtml.indexOf(
                        'Original Message',
                      );
                      const body =
                        i > 0
                          ? log.email.textAsHtml.substring(0, i)
                          : log.email.textAsHtml;
                      return (
                        <div
                          onClick={() => {
                            setEmailDetails(log.email);
                            setViewEmail(true);
                          }}
                          key={`${log.createdDateTime}${log.note}`}
                          className={
                            log.state
                              ? 'email-container received-email'
                              : 'email-container sent-email'
                          }
                        >
                          <div className="email-header">
                            <div
                              style={{
                                display: 'flex',
                                width: '100%',
                                flexDirection: 'row-reverse',
                              }}
                            >
                              <div className="email-time">
                                {getFormattedDateTime(log.email.date)}
                              </div>
                              <div
                                className="email-icon-list"
                                style={{
                                  backgroundColor: log.state
                                    ? 'coral'
                                    : 'darkcyan',
                                }}
                              >
                                {getInitials(log.email)}
                              </div>
                            </div>
                            <div className="email-from">
                              <div>{log.email.patientName}</div>
                              <span>{`<${log.email.patientEmailAddress}>`}</span>
                            </div>
                          </div>
                          <div className="email-subject">
                            {`${log.email.subject}`}
                          </div>
                          <div className="email-body">
                            {strip_html_tags(body)}
                          </div>
                        </div>
                      );
                    }
                    return null;
                  })
                ) : (
                  <div className="no-emails-placeholder">
                    No Emails in this case
                  </div>
                )}
              </div>
            )}
          </div>
        ) : null}
      </ReactCSSTransitionGroup>

      <Modal className="email-modal" show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>New Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Subject</Form.Label>
            <Form.Control
              type="text"
              value={
                contactCase && contactCase.logs && contactCase.logs[1].email
                  ? `Re: ${contactCase.logs[1].email.subject}`
                  : outboundEmail.subject
              }
              readOnly={
                contactCase && contactCase.logs && contactCase.logs[1].email
                  ? true
                  : false
              }
              onChange={(e: any) =>
                setOutboundEmail({ ...outboundEmail, subject: e.target.value })
              }
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>Body</Form.Label>
            <Form.Control
              required
              type="text"
              as="textarea"
              id="email-body-text-area"
              rows={10}
              onChange={(e: any) =>
                setOutboundEmail({
                  ...outboundEmail,
                  bodyHtml:
                    e.target.value +
                    '\n <hr><section>' +
                    getPreviousEMails(contactCase) +
                    '</section></hr>',
                })
              }
            />
            <Form.Control.Feedback type="invalid">
              Please input a note for this case
            </Form.Control.Feedback>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="submit"
            className="case-button green-case-button"
            onClick={handleSend}
          >
            Send
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default Email;

/* eslint-disable  space-in-parens */

import React, { useContext, useState, useEffect, useCallback } from 'react';
import { Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Form,
  Modal,
  Button,
  OverlayTrigger,
  Tooltip,
  Card,
  Accordion,
  Container,
  Toast,
  Row,
  Col,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faMicrophone,
  faTrash,
  faArrowDown,
  faAngleDown,
} from '@fortawesome/free-solid-svg-icons';
import { ContactContext, AuthContext } from '../../models/common';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import {
  CaseContext,
  CaseLog,
  CaseState,
  CaseStatus,
  CaseSkill,
  InboundChannel,
  OutboundChannel,
} from '../../models/cases';
import { caseContext as _caseContext } from '../../context/case/caseContext';
import { authContext as _authContext } from '../../context/auth/authContext';
import connect from '../../config/connect';

import { markDuplicate } from '../../services/case.service';
import { printStatus } from '../../models/utils';
import '../../assets/css/checkbox.scss';
import '../../assets/css/contact-detail.scss';
import PatientConditions from '../Patients/PatientConditions';

// for testing purposes pull call from here instead of ContactContext
// import contactCase from './assets/dummyCall';

const ContactInfo = (props) => {
  const contactContext: ContactContext = useContext(_contactContext);
  const caseContext: CaseContext = useContext(_caseContext);
  const authContext: AuthContext = useContext(_authContext);
  const completeContact = contactContext.completeContact!;
  const saveCase = contactContext.saveCase!;
  const getDownload = caseContext.getDownload!;
  const deleteCase = caseContext.deleteCase!;
  const { contactCase } = contactContext;
  const [validated, setValidated] = useState(false);

  const [errorMessage, setErrorMessage] = useState('');
  const [audioFile, setAudioFile] = useState('');
  const [toastMessage, setToastMessage] = useState('');
  const [actionPerformed, setactionPerformed] = useState(false);
  const [showA, setShowA] = useState(false);
  const [showConfirm, setShowConfirm] = useState(false);
  const [redirect, setRedirect] = useState(false);
  const [modalShow, setModalShow] = React.useState(false);
  const [caseLog, setCaseLog] = useState<CaseLog>({
    category: 'GENERAL_INQ',
    note: '',
    topics: [],
    followUp: false,
    transfer: false,
    skill: undefined,
    status: undefined,
    state: undefined,
    inboundChannel: undefined,
    outboundChannel: undefined,
  });

  const [category, setCategory] = useState('GENERAL_INQ');
  const actionOptions = {
    UNSELECTED: '',
    COMPLETE: 'COMPLETE',
    PENDING: 'PENDING',
    ESCALATED: 'ESCALATED',
    DUPLICATE: 'DUPLICATE',
    SPAM: 'SPAM',
    ABANDONED: 'ABANDONED',
    TEST: 'TEST',
  };
  const [action, setAction] = useState(actionOptions.UNSELECTED);
  const { user } = authContext;
  const notify = (messageToDisplay) => {
    setToastMessage(messageToDisplay);
    setactionPerformed(true);
    toggleShowA();
  };
  const toggleShowA = () => {
    setShowA(true);
    setTimeout(() => setShowA(false), 5000);
  };

  useEffect(() => {
    if (contactCase) {
      setCategory(contactCase.category ? contactCase.category : 'GENERAL_INQ');
      setCaseLog({
        ...caseLog,
        topics: contactCase.topics,
        inboundChannel: contactCase.inboundChannel,
      });
    }
  }, [contactCase]); // eslint-disable-line react-hooks/exhaustive-deps

  const getFormattedDateTime = useCallback((date) => {
    const d = new Date(date);
    return (
      <span>
        <span>
          {`${d.toLocaleDateString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: '2-digit',
          })}`}
        </span>
        &nbsp;&nbsp;
        <span>{`${d.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
        })}`}</span>
      </span>
    );
  }, []);

  const getFormattedDate = useCallback((date) => {
    const d = new Date(date);
    return d.toLocaleDateString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: '2-digit',
    });
  }, []);

  // destroys cpp contact connection is it exists
  const destroyConnection = () => {
    if (window.myCPP && window.myCPP.contact) {
      try {
        const connection = window.myCPP.contact.getAgentConnection();
        if (connection) {
          connection.destroy();
        }
      } catch (error) {
        // TODO error notifications
      }
    }
  };

  const createFollowUpCase = async () => {
    destroyConnection();
    const verifiedChanges = props.onClose();
    if (verifiedChanges) await completeContact(contactCase, caseLog);
    else props.showSaveDataWarning();
    // await completeContact(contactCase, caseLog);
  };

  const transferCase = async () => {
    if (contactCase!.inboundChannel === 'PHONE') {
      const { agent } = window.myCPP;
      let transferQueue;
      const transferEndpoint =
        user && user.groups[0] === 'CSR' ? 'CDTransfer' : 'CSRTransfer';
      agent.getEndpoints(agent.getAllQueueARNs(), {
        success: (data) => {
          transferQueue = data.endpoints.find(
            (endpoint) => endpoint.name === transferEndpoint,
          );
          agent
            .getContacts(connect.ContactType.VOICE)[0]
            .addConnection(transferQueue, {
              success: async () => {
                // await completeContact(contactCase, caseLog);
                const verifiedChanges = props.onClose();
                if (verifiedChanges)
                  await completeContact(contactCase, caseLog);
                else props.showSaveDataWarning();
                window.myCPP.contact.getAgentConnection().destroy();
              },
            });
        },
        failure: () => {
          // TODO error notifications
        },
      });
    } else {
      // await completeContact(contactCase, caseLog);
      const verifiedChanges = props.onClose();
      if (verifiedChanges) await completeContact(contactCase, caseLog);
      else props.showSaveDataWarning();
    }
  };

  const getCategoryName = (category) => {
    switch (category) {
      case 'ADVERSE_EVENT':
        return 'Adverse Event';
      case 'INVENTORY':
        return 'Inventory';
      case 'PRODUCT_INFO':
        return 'Product Information';
      case 'MEDICAL_INQ':
        return 'Medical Inquiry';
      case 'GENERAL_INQ':
        return 'General Inquiry';
    }
  };

  const handleAction = (event) => {
    switch (action) {
      case actionOptions.COMPLETE: {
        actionPerformed
          ? notify('Action Already Performed')
          : setCaseLog({
              ...caseLog,
              status: CaseStatus.COMPLETED,
              skill:
                user && user.groups.length ? user.groups[0] : CaseSkill.CSR,
            });
        notify('Case Marked as Completed');
        break;
      }
      case actionOptions.ESCALATED: {
        actionPerformed
          ? notify('Action Already Performed')
          : setCaseLog({
              ...caseLog,
              status: CaseStatus.PENDING,
              state: CaseState.INBOUND,
              transfer: true,
              skill:
                user && user.groups[0] === CaseSkill.CSR
                  ? CaseSkill.CD
                  : CaseSkill.CSR,
            });
        notify(
          `Transfered To ${
            user && user.groups[0] === CaseSkill.CSR
              ? CaseSkill.CD
              : CaseSkill.CSR
          }`,
        );
        break;
      }
      case actionOptions.PENDING: {
        setCaseLog({
          ...caseLog,
          status: CaseStatus.PENDING,
          state: CaseState.OUTBOUND,
          followUp: true,
        });
        notify('Case Marked for Follow Up');
        break;
      }
      case actionOptions.SPAM: {
        setCaseLog({
          ...caseLog,
          note: 'MARKED_AS_SPAM',
          status: CaseStatus.CLOSED,
          state: CaseState.INBOUND,
          followUp: false,
        });
        notify('Case Marked as Spam');
        break;
      }
      case actionOptions.DUPLICATE: {
        setCaseLog({
          ...caseLog,
          note: 'MARKED_AS_DUPLICATE',
          status: CaseStatus.CLOSED,
          state: CaseState.INBOUND,
          followUp: false,
        });
        notify('Case Marked for Duplicate');
        break;
      }
      case actionOptions.ABANDONED: {
        setCaseLog({
          ...caseLog,
          note: 'CALL_ABANDONED',
          status: CaseStatus.CLOSED,
          state: CaseState.INBOUND,
          followUp: false,
        });
        notify('Case Marked for Duplicate');
        break;
      }
      case actionOptions.TEST: {
        setCaseLog({
          ...caseLog,
          note: 'Test Case',
          status: CaseStatus.COMPLETED,
          state: CaseState.INBOUND,
          followUp: false,
        });
        notify('Case Marked as Test');
        break;
      }
    }
  };

  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    if (form.checkValidity() === true) {
      if (action === actionOptions.DUPLICATE)
        await markDuplicate(caseLog.parentCase!, contactCase!.caseNumber);
      // check if this is an active contact or viewing old contact details
      if (props.active) {
        // otherwise these things will be done in either transferCase or createFollowUpCase
        if (!caseLog.transfer && !caseLog.followUp) {
          destroyConnection();
          const verifiedChanges = props.onClose();
          if (verifiedChanges) await completeContact(contactCase, caseLog);
          else props.showSaveDataWarning();
        }

        let error = '';

        if (caseLog.followUp) {
          if (
            caseLog.outboundChannel === OutboundChannel.EMAIL &&
            !contactCase!.patient.emailAddress
          ) {
            error =
              'Patient email address is required to create an outbound email case';
          } else if (
            caseLog.outboundChannel === OutboundChannel.PHONE &&
            !contactCase!.patient.phoneNumber
          ) {
            error =
              'Patient phone number is required to create an outbound phone case';
          } else {
            createFollowUpCase();
          }
        }

        if (error) {
          setErrorMessage(error);
        } else if (caseLog.transfer) {
          transferCase();
        }
      } else {
        saveCase(contactCase, caseLog);
        setCategory(
          contactCase!.category ? contactCase!.category : 'GENERAL_INQ',
        );
        setCaseLog({ ...caseLog, note: ' ' });
      }
    }
    setValidated(true);
  };

  const renderRedirect = () => {
    if (redirect) {
      return <Redirect to="/cases" />;
    }
    return null;
  };

  const transformCapitalization = (word) =>
    word &&
    word
      .toLowerCase()
      .charAt(0)
      .toUpperCase() + word.toLowerCase().slice(1);

  const conversions: any = React.useMemo(() => {
    const arr =
      contactCase && contactCase.logs && contactCase.logs.length > 0
        ? contactCase!.logs.filter(
            (log) => !log.logType || log.logType !== 'SYSTEM',
          )
        : [];
    return arr.reverse();
  }, [contactCase]);
  const histories: any = React.useMemo(() => {
    const arr =
      contactCase && contactCase.logs && contactCase.logs.length > 0
        ? contactCase!.logs.filter(
            (log) => log.logType && log.logType === 'SYSTEM',
          )
        : [];
    return arr.reverse();
  }, [contactCase]);

  return (
    <div className="case-detail-container--background">
      {contactCase ? (
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <div id="case-detail-container">
            <div id="case-detail-header">
              <div id="case-detail-header-title">
                {props.active ? 'Case In Progress' : 'Case Details'}
              </div>
              <div id="case-detail-header-details">
                <div>
                  Case ID: &nbsp;
                  <span>{contactCase!.caseNumber}</span>
                </div>
                <div>
                  Status: &nbsp;
                  <span>{printStatus(contactCase!)}</span>
                </div>
                <div>
                  Type: &nbsp;
                  <span
                    style={{ whiteSpace: 'nowrap' }}
                  >{`${transformCapitalization(
                    contactCase!.state,
                  )} ${transformCapitalization(
                    contactCase!.inboundChannel!,
                  )}`}</span>
                </div>
                <div>
                  Skill: &nbsp;
                  <span>{contactCase!.skill}</span>
                </div>
                <div>
                  Created: &nbsp;
                  <span>{getFormattedDate(contactCase!.createdDateTime)}</span>
                </div>
                <div>
                  Dispensary: &nbsp;
                  <span>{contactCase!.dispensary.name}</span>
                </div>
              </div>
            </div>

            <div id="case-detail-body">
              <div id="case-log-checkboxes">
                <span className="case-log-title">Category:</span>
                <Form.Group>
                  <Form.Control
                    as="select"
                    className="mr-sm-2"
                    onChange={(e: any) => {
                      setCaseLog({ ...caseLog, category: e.target.value });
                      setCategory(e.target.value);
                    }}
                    value={category || 'GENERAL_INQ'}
                  >
                    <option value="ADVERSE_EVENT">Adverse Event</option>
                    <option value="INVENTORY">Inventory</option>
                    <option value="PRODUCT_INFO">Product Information</option>
                    <option value="MEDICAL_INQ">Medical Inquiries</option>
                    <option value="GENERAL_INQ">General Inquiries</option>
                    <option value="INELIGIBLE_PATIENT">
                      Ineligible Patient
                    </option>
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                    Please select a category for this case
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <Row>
                <Col>
                  <Form.Group id="case-notes" className="h-100">
                    <Form.Control
                      // TODO: check if required
                      className="h-100"
                      required
                      type="text"
                      as="textarea"
                      id="case-note-text-area"
                      placeholder={
                        contactCase!.inboundChannel === InboundChannel.PHONE &&
                        props.active
                          ? 'What happened during the call?'
                          : 'Notes on this case...'
                      }
                      rows={10}
                      onChange={(e: any) =>
                        setCaseLog({ ...caseLog, note: e.target.value })
                      }
                      value={caseLog.note}
                    />
                    <Form.Control.Feedback type="invalid">
                      Please input a note for this case
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              {/* Log Section  */}
              {contactCase && contactCase.logs && contactCase.logs.length ? (
                <Container className="w-100">
                  <Modal
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    id="logDetails"
                    dialogClassName="my-modal"
                  >
                    <Modal.Body className="body-modal">
                      <Card className="card-style">
                        <Card.Header className="card-header owncss">
                          Log History Details
                        </Card.Header>
                        {histories && histories.length > 0 ? (
                          <Accordion>
                            {histories.map((log, index) => (
                              <Card key={`${log.createdDateTime}`}>
                                <Accordion.Toggle
                                  as={Card.Header}
                                  eventKey={index.toString()}
                                  className="title-accordian owncsstxt"
                                >
                                  {getFormattedDateTime(log.createdDateTime)} :{' '}
                                  {log.note.includes('Call')
                                    ? `(Call) : `
                                    : log.note.includes('Email')
                                    ? `(Email) : `
                                    : `(Note) : `}
                                  {`${log.note}`}
                                  <span className="ownspan">
                                    <FontAwesomeIcon icon={faAngleDown} />
                                  </span>
                                </Accordion.Toggle>
                                <Accordion.Collapse
                                  className="content-css"
                                  eventKey={index.toString()}
                                >
                                  <Card.Body
                                    className={
                                      log.note.includes('Email')
                                        ? `email-modal`
                                        : `call-body`
                                    }
                                  >
                                    {/* <pre className="align-center"> */}
                                    {log.note.includes('Email') && log.email ? (
                                      <Container>
                                        <Card>
                                          {log.email.subject ? (
                                            <Card.Header>
                                              {' '}
                                              <b>Subject : </b>
                                              {log.email.subject}{' '}
                                            </Card.Header>
                                          ) : (
                                            `No Log`
                                          )}
                                          {log.email.html ? (
                                            <Card.Body>
                                              {' '}
                                              <pre style={{ padding: '1rem' }}>
                                                {' '}
                                                <div
                                                  dangerouslySetInnerHTML={{
                                                    __html: log.email.html,
                                                  }}
                                                ></div>
                                              </pre>{' '}
                                            </Card.Body>
                                          ) : null}
                                        </Card>
                                      </Container>
                                    ) : log.note.includes('Call') ? (
                                      log.note
                                    ) : null}
                                    {log.callRecordingS3Location ? (
                                      <span style={{ fontSize: '14px' }}>
                                        {' '}
                                        <b>&nbsp; Audio</b>
                                      </span>
                                    ) : null}
                                    {log.callRecordingS3Location ? (
                                      <OverlayTrigger
                                        placement="top"
                                        overlay={
                                          <Tooltip id="call-recording">
                                            Play Recording
                                          </Tooltip>
                                        }
                                      >
                                        <span
                                          role="button"
                                          tabIndex={0}
                                          style={{
                                            cursor: 'pointer',
                                            marginLeft: '.5rem',
                                          }}
                                          onClick={async () => {
                                            const recording = await getDownload(
                                              log.callRecordingS3Location,
                                            );
                                            setAudioFile(recording.downloadUrl);
                                          }}
                                          onKeyPress={async () => {
                                            const recording = await getDownload(
                                              log.callRecordingS3Location,
                                            );
                                            setAudioFile(recording.downloadUrl);
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={faMicrophone}
                                          />
                                        </span>
                                      </OverlayTrigger>
                                    ) : null}
                                    {log.callRecordingS3Location ? (
                                      <span style={{ fontSize: '14px' }}>
                                        {' '}
                                      </span>
                                    ) : null}
                                  </Card.Body>
                                </Accordion.Collapse>
                              </Card>
                            ))}
                          </Accordion>
                        ) : (
                          'No Log For this Case'
                        )}
                      </Card>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button onClick={() => setModalShow(false)}>Close</Button>
                    </Modal.Footer>
                  </Modal>
                  <Row>
                    <Col>
                      <Card className="card-style mt-0 h-100">
                        <Card.Header className="card-header owncss">
                          Conversations
                        </Card.Header>
                        <div className="scroll-custom">
                          {conversions && conversions.length > 0 ? (
                            <Card.Body className="card-body scroll-custom">
                              {conversions.map((log) => (
                                <Card.Text
                                  className="card-text"
                                  key={`${log.createdDateTime}${log.note}`}
                                >
                                  <b>
                                    {getFormattedDateTime(log.createdDateTime)}{' '}
                                    :{' '}
                                    {log.note.includes('Call')
                                      ? `(Call) : `
                                      : log.note.includes('Email')
                                      ? `(Email) : `
                                      : `(Note :: ${
                                          log.category
                                            ? getCategoryName(log.category)
                                            : ''
                                        }) : `}
                                  </b>
                                  {` ${log.note}`}
                                  {log.callRecordingS3Location ? (
                                    <span style={{ fontSize: '14px' }}>
                                      {' '}
                                      &nbsp;Audio
                                    </span>
                                  ) : null}
                                  {log.callRecordingS3Location ? (
                                    <OverlayTrigger
                                      placement="top"
                                      overlay={
                                        <Tooltip id="call-recording">
                                          Play Recording
                                        </Tooltip>
                                      }
                                    >
                                      <span
                                        role="button"
                                        tabIndex={0}
                                        style={{
                                          cursor: 'pointer',
                                          marginLeft: '.5rem',
                                        }}
                                        onClick={async () => {
                                          const recording = await getDownload(
                                            log.callRecordingS3Location,
                                          );
                                          setAudioFile(recording.downloadUrl);
                                        }}
                                        onKeyPress={async () => {
                                          const recording = await getDownload(
                                            log.callRecordingS3Location,
                                          );
                                          setAudioFile(recording.downloadUrl);
                                        }}
                                      >
                                        <FontAwesomeIcon icon={faMicrophone} />
                                      </span>
                                    </OverlayTrigger>
                                  ) : null}

                                  {log.callRecordingS3Location ? (
                                    <span style={{ fontSize: '14px' }}> </span>
                                  ) : null}
                                </Card.Text>
                              ))}
                            </Card.Body>
                          ) : (
                            'No History for Conversation'
                          )}
                        </div>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              ) : null}

              {props.active ? (
                <div id="case-log-checkboxes">
                  <Form.Group style={{ marginRight: '1rem' }}>
                    <Form.Control
                      as="select"
                      className="mr-sm-2"
                      onChange={(e: any) => {
                        setAction(e.target.value);
                        setCaseLog({
                          ...caseLog,
                          followUp: e.target.value === actionOptions.PENDING,
                        });
                        // setResolve(e.target.value === actionOptions.COMPLETE);
                        // setCaseLog({ ...caseLog, category: e.target.value });
                        // setCategory(e.target.value);
                      }}
                      value={action || actionOptions.UNSELECTED}
                    >
                      <option value={actionOptions.UNSELECTED}>
                        - Select Status -
                      </option>
                      <option value={actionOptions.COMPLETE}>
                        Resolve Case
                      </option>
                      <option value={actionOptions.ESCALATED}>{`${
                        user && user.groups[0] === 'CD'
                          ? 'Transfer to CSR'
                          : 'Escalate Case'
                      }`}</option>
                      <option
                        value={actionOptions.PENDING}
                        onSelect={() => {
                          actionPerformed
                            ? notify('Action Already Performed')
                            : notify('FollowUp Case Created');
                        }}
                      >
                        Pending Information from Patient
                      </option>
                      <option value={actionOptions.SPAM}>Spam</option>
                      <option value={actionOptions.DUPLICATE}>
                        Duplicate Case
                      </option>
                      <option value={actionOptions.ABANDONED}>
                        Abandoned Case
                      </option>
                      <option value={actionOptions.TEST}>Test Case</option>
                    </Form.Control>
                    <Form.Control.Feedback type="invalid">
                      Please select a status for this case
                    </Form.Control.Feedback>
                  </Form.Group>
                  {action === actionOptions.DUPLICATE && (
                    <Form.Group style={{ marginRight: '1rem' }}>
                      <Form.Control
                        required
                        type="text"
                        placeholder="Original Case no."
                        onChange={(e: any) => {
                          setCaseLog({
                            ...caseLog,
                            parentCase: Number(e.target.value),
                          });
                        }}
                      />
                      <Form.Control.Feedback type="invalid">
                        Please enter the original case number.
                      </Form.Control.Feedback>
                    </Form.Group>
                  )}
                  {action === actionOptions.PENDING && (
                    <div className="flex-center">
                      <span className="case-log-title">How to follow up?</span>
                      <Form.Group className="case-log-checkboxes--form display-none">
                        {['PHONE', 'EMAIL'].map((option: any) => (
                          <Form.Check
                            required
                            inline
                            type="radio"
                            name="caseChannel"
                            label={option}
                            key={option}
                            defaultChecked={
                              option.toUpperCase() ===
                              contactCase!.inboundChannel
                            }
                            onClick={() =>
                              setCaseLog({
                                ...caseLog,
                                outboundChannel:
                                  OutboundChannel[option.toUpperCase()],
                              })
                            }
                          />
                        ))}
                      </Form.Group>
                    </div>
                  )}
                  <button
                    // disabled = {actionPerformed}
                    type="submit"
                    className="case-button green-case-button"
                    onClick={handleAction}
                  >
                    Submit
                  </button>
                </div>
              ) : null}
            </div>
            {props.active ? null : (
              <div id="case-detail-actions">
                <button
                  type="button"
                  className="btn transparent border-btn"
                  onClick={() => setModalShow(true)}
                >
                  History <FontAwesomeIcon icon={faArrowDown} size="1x" />
                </button>
                {user && user!.groups[0] === 'CD' && (
                  <button
                    type="button"
                    className="delete-button"
                    onClick={() => {
                      setShowConfirm(true);
                    }}
                  >
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                )}
                <button type="submit" className="case-button green-case-button">
                  Save Case
                </button>
              </div>
            )}
          </div>
          <Toast
            className="outer-container--toast"
            style={{ backgroundColor: 'green', color: 'white' }}
            show={showA}
            autohide
          >
            <Toast.Body>{toastMessage}</Toast.Body>
          </Toast>
        </Form>
      ) : null}

      <Modal
        size="sm"
        show={errorMessage.length > 0}
        onHide={() => setErrorMessage('')}
      >
        <Modal.Body>{errorMessage}</Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setErrorMessage('')}>OK</Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={audioFile.length > 0}
        onHide={() => setAudioFile('')}
        centered
      >
        <Modal.Body
          style={{
            display: 'flex',
            backgroundColor: '#000',
            justifyContent: 'center',
            paddingTop: '0',
          }}
        >
          <iframe title="audio-file" src={audioFile} />
        </Modal.Body>
        <Modal.Footer>
          <button
            className="case-button silver-case-button"
            onClick={() => setAudioFile('')}
            type="button"
          >
            Close
          </button>
        </Modal.Footer>
      </Modal>
      {props.active ? null : renderRedirect()}
      {props.active ? null : (
        <Modal show={showConfirm} onHide={() => setShowConfirm(false)}>
          <Modal.Body>Are you sure you want to delete this case?</Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="cases-button silver-cases-button"
              onClick={() => setShowConfirm(false)}
            >
              No
            </button>
            <button
              type="button"
              className="cases-button silver-cases-button"
              onClick={async () => {
                setShowConfirm(false);
                await deleteCase(
                  contactCase!.dispensary.id,
                  contactCase!.caseNumber,
                );
                setRedirect(true);
              }}
            >
              Yes
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default ContactInfo;

ContactInfo.propTypes = {
  active: PropTypes.bool,
  onClose: PropTypes.func,
  showSaveDataWarning: PropTypes.func,
};

ContactInfo.defaultProps = {
  active: false,
};

import React, { useReducer } from 'react';
import { themeContext as ThemeContext } from './themeContext';
import themeReducer from './themeReducer';
import { LIGHT_THEME } from '../types';

const ThemeState = (props) => {
  const initialState = {
    appTheme: localStorage.getItem('appTheme') || LIGHT_THEME,
  };

  const [state, dispatch] = useReducer(themeReducer, initialState);

  return (
    <ThemeContext.Provider value={state}>
      {props.children}
    </ThemeContext.Provider>
  );
};

export default ThemeState;

// TODO fix this typescript error
const env = process.env.REACT_APP_ENV||'';
// const env = 'staging';

const environments = {
  dev: {
    apiUrl: 'https://brq1hlf5m8.execute-api.us-east-1.amazonaws.com/dev',
    ccpUrl: 'https://cannovate-dev.awsapps.com/connect/ccp#',
  },
  staging: {
    apiUrl: 'https://owhr86ig97.execute-api.us-east-1.amazonaws.com/staging',
    ccpUrl: 'https://cannovate-stage.awsapps.com/connect/ccp#',
  },
  prod : {
    apiUrl: 'https://n1cou5uc66.execute-api.us-east-1.amazonaws.com/prod',
    ccpUrl: 'https://cannovate-prod.my.connect.aws/connect/ccp#'
  }
};

export const ccpUrl = environments[env].ccpUrl;
export const apiUrl = environments[env].apiUrl;

import { GET_PATIENTS_SUCCESS, GET_PATIENTS_FAIL, GET_PATIENTS_BY_DISPENSARY } from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.payload,
      };
    case GET_PATIENTS_BY_DISPENSARY:
      return {
        patients: action.payload,
      }
    case GET_PATIENTS_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

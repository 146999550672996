import React from 'react';
import PropTypes from 'prop-types';
import AuthState from './auth/AuthState';
import CaseState from './case/caseState';
import ContactState from './contact/contactState';
import PatientState from './patient/patientState';
import DispensaryState from './dispensary/dispensaryState';
import AgentState from './sidePanel/sidePanelState';
import ThemeState from './theme/themeState';
import window from '../components/Phone/SoftPhone';

const AppContext = ({ children }) => (
  <ThemeState>
    <AuthState>
      <AgentState>
        <ContactState>
          <CaseState>
            <DispensaryState>
              <PatientState>{children}</PatientState>
            </DispensaryState>
          </CaseState>
        </ContactState>
      </AgentState>
    </AuthState>
  </ThemeState>
);

export default AppContext;

AppContext.propTypes = {
  children: PropTypes.shape({}),
};

AppContext.defaultProps = {
  children: {},
};

import {
  GET_DISPENSARIES_SUCCESS,
  GET_DISPENSARIES_FAIL,
  GET_DISPENSARY_SUCCESS,
  GET_DISPENSARY_FAIL,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case GET_DISPENSARIES_SUCCESS:
      return {
        ...state,
        dispensaries: action.payload,
      };
    case GET_DISPENSARIES_FAIL:
      return {
        ...state,
      };
    case GET_DISPENSARY_SUCCESS:
      return {
        ...state,
        dispensary: action.payload,
      };
    case GET_DISPENSARY_FAIL:
      return {
        ...state,
      };
    default:
      return state;
  }
};

import {
  RENDER_CALL,
  SHOW_CALL,
  END_CONTACT,
  UPDATE_CONTACT_CASE_PATIENT,
  SET_CONTACT_CASE,
  SHOW_EMAIL,
  SEND_EMAIL,
  CONTACT_ERROR,
  CLEAR_ERROR,
  SAVE_CASE,
  ON_GOING_CALL,
} from '../types';

export default (state, action) => {
  switch (action.type) {
    case RENDER_CALL:
      return {
        ...state,
        renderCall: true,
      };
    case SHOW_CALL:
      return {
        ...state,
        showCall: state.showCall + 1,
      };
    case END_CONTACT:
      return {
        ...state,
        contactCase: null,
        showCall: 0,
        showEmail: 0,
      };
    case UPDATE_CONTACT_CASE_PATIENT:
      return {
        ...state,
        contactCase: {
          ...state.contactCase,
          patient: action.payload,
        },
      };
    case SET_CONTACT_CASE:
      return {
        ...state,
        contactCase: action.payload,
      };
    case SHOW_EMAIL:
      return {
        ...state,
        renderEmail: true,
        showEmail: state.showEmail + 1,
      };
    case SEND_EMAIL:
      return {
        ...state,
        contactCase: {
          ...state.contactCase,
          logs: [
            action.payload,
            ...state.contactCase.logs,
          ],
        },
      };
    case CONTACT_ERROR:
      return {
        ...state,
        contactError: action.payload,
      };
    case CLEAR_ERROR:
      return {
        ...state,
        contactError: '',
      };
    case SAVE_CASE:
      return {
        ...state,
        contactCase: action.payload,
      };
    case ON_GOING_CALL:
      return {
        ...state,
        onGoingCall: action.payload,
      };      
    default:
      return state;
  }
};

import axios from 'axios';
import { Auth } from 'aws-amplify';

const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

let isAlreadyFetchingAccessToken = false;
let subscribers: any[] = [];

function onAccessTokenFetched(accessToken) {
  subscribers.forEach((callback) => callback(accessToken));
  subscribers = [];
}

function addSubscriber(callback) {
  subscribers.push(callback);
}

async function resetTokenAndReattemptRequest(error) {
  try {
    const { response: errorResponse } = error;

    const resetToken = localStorage.getItem('refreshToken') || '';

    if (!resetToken) {
      return Promise.reject(error);
    }

    const retryOriginalRequest = new Promise((resolve) => {
      addSubscriber((accessToken) => {
        errorResponse.config.headers.Authorization = `Bearer ${accessToken}`;
        resolve(axios(errorResponse.config));
      });
    });

    if (!isAlreadyFetchingAccessToken) {
      isAlreadyFetchingAccessToken = true;

      const cognitoUser = await Auth.currentAuthenticatedUser();
      const currentSession: any = await Auth.currentSession();

      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          if (err) {
            return Promise.reject(error);
          }

          const { idToken, refreshToken } = session;

          const auth = {
            token: idToken.jwtToken,
            refreshToken: refreshToken.token,
          };
          setAuthToken(auth.token);
          localStorage.setItem('token', auth.token);
          localStorage.setItem('refreshToken', auth.refreshToken);
          isAlreadyFetchingAccessToken = false;
          return onAccessTokenFetched(auth.token);
        },
      );
    }
    return retryOriginalRequest;
  } catch (err) {
    return Promise.reject(err);
  }
}

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const errorResponse = error.response;
    if (errorResponse && errorResponse.status === 401) {
      return resetTokenAndReattemptRequest(error);
    }

    return Promise.reject(error);
  },
);

export default setAuthToken;

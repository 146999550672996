import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import envVariables from '../../config/cognito';
import { Toast } from 'react-bootstrap';
import '../../assets/css/login.scss';
import CannovateBanner from './CannovateBanner';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [successToast, setSuccessToast] = useState(false);
  const [failureToast, setFailureToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeEmail = (e) => setEmail(e.target.value);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFailureToast(false);
    setSuccessToast(false);
    if (email != '') {
      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: envVariables.Auth.userPoolId, // replace by yours
        ClientId: envVariables.Auth.userPoolWebClientId, // replace by yours
      });

      const userData = {
        Username: email,
        Pool: userPool,
      };
      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.forgotPassword({
        onSuccess: function(result) {
          setSuccessToast(true);
          setTimeout(() => props.history.push('/reset'), 3000);
        },
        onFailure: function(err) {
          setErrorMessage(err.message);
          setFailureToast(true);
          setEmail('');
        },
      });
    }
  };

  const logo = <img src="/images/logo_2019-10-01/logo@3x.png" alt="logo" />;
  return (
    <div id="app-login" className="app-login">
      <CannovateBanner />
      <div className="app-login-wrapper">
        <div className="login-form-top">
          <span>Forgot Password ?</span>
        </div>
        <div className="login-form-bottom">
          <form>
            <div className="form-group">
              <div style={{ display: successToast ? 'block' : 'none' }}>
                <Toast
                  className="login-input"
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    maxWidth: '100%',
                  }}
                  autohide={true}
                  show={successToast}
                  delay={1500}
                >
                  <Toast.Body style={{ width: '100%' }}>
                    Password reset code has been sent to your email address!
                  </Toast.Body>
                </Toast>
              </div>
              <div style={{ display: failureToast ? 'block' : 'none' }}>
                <Toast
                  className="login-input"
                  style={{
                    backgroundColor: '#f8d7da',
                    color: '#721c24',
                    maxWidth: '100%',
                    height: 'unset',
                  }}
                  autohide={true}
                  show={failureToast}
                  delay={1500}
                >
                  <Toast.Body style={{ width: '100%' }}>
                    <b>Error!</b>
                    <ul
                      style={{ marginBottom: 'unset', listStyleType: 'bullet' }}
                    >
                      <li>
                        {`\u2022`} {errorMessage}
                      </li>
                    </ul>
                  </Toast.Body>
                </Toast>
              </div>
              <label htmlFor="Enter email to recover password">
                <span className="login-label">
                  Enter email to recover password
                </span>
                <input
                  className="login-input"
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChangeEmail}
                  required
                />
              </label>
            </div>
            <input
              type="submit"
              value="Submit Email"
              className="login-button"
              onClick={handleSubmit}
            />
          </form>
        </div>
        <Link className="forgot-password" to="/">
          Login
        </Link>
        <Link className="forgot-password" to="/reset" style={{ float: 'left' }}>
          Already have Reset Code ?
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;

import React, { useEffect, useState } from 'react';
import { Spinner, Dropdown, InputGroup, Form, Button } from 'react-bootstrap';
import cleanDeep from 'clean-deep';
import axios from 'axios';
import { apiUrl } from '../../config/environments';
import { getDispensaryColor } from '../../models/dispensaries';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faSearch } from '@fortawesome/free-solid-svg-icons';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import { FormControl, Grid } from '@material-ui/core';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import FilterListIcon from '@material-ui/icons/FilterList';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { Order } from '../../models/common';
import { dispensaries } from '../../config/dispenseries';
import '../../assets/css/patients.scss';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: '#71D300',
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  table: {
    minWidth: 700,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ManagePatients = React.memo((props) => {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [patients, setPatients] = React.useState();
  const [counts, setCounts] = React.useState(0);
  const [params, setParams] = React.useState({});
  const [showFilter, setShowFilter] = React.useState(true);

  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState('PATIENTLASTNAME');
  const [dispensaryName, setDispensaryName] = useState('Dispensary');

  const handleShowFilter = (event, newFlag) => {
    if (event) event.preventDefault();
    setShowFilter(newFlag);
  };

  const [initValues] = useState({
    dispensaryId: '',
    patientFirstName: '',
    patientLastName: '',
    patientPhone: '',
    patientEmail: '',
  });

  const [inputs, setInputs] = React.useState(initValues);

  const handleChangInput = (event) => {
    event.persist();
    setInputs((inputs) => ({
      ...inputs,
      [event.target.name]: event.target.value,
    }));
  };

  const handleDropDowns = (target, value) => {
    if (target === 'dispensary') {
      setInputs((inputs) => ({ ...inputs, dispensaryId: value }));
      setDispensaryName(
        dispensaries.find((dispensery) => {
          return dispensery.id === Number(value);
        }).name,
      );
    }
  };

  const handleSubmit = (event: any) => {
    if (event) event.preventDefault();
    setPatients(undefined);
    setPage(0);
    setParams(inputs);
  };

  const handleReset = (event: any) => {
    if (event) event.preventDefault();
    setPatients(undefined);
    setPage(0);
    setInputs(initValues);
    setParams(initValues);
    setDispensaryName('Dispensary');
  };
  const handleChangePage = (event, newPage) => {
    setPatients(undefined);
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPatients(undefined);
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const createSortHandler = (property: string) => (
    event: React.MouseEvent<unknown>,
  ) => {
    if (event) event.preventDefault();
    const isAsc = orderBy === property && order === 'asc';
    setPatients(undefined);
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setPage(0);
  };

  const getPatients = async () => {
    try {
      const res = await axios.get(`${apiUrl}/patients`, {
        params: {
          ...cleanDeep(params),
          order: order,
          orderBy: orderBy,
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          getCount: 1,
        },
      });
      setCounts(res.data.count);
      setPatients(res.data.records);
    } catch (e) {
      console.error('Patients Error', e);
    }
  };

  useEffect(() => {
    if (!patients) getPatients();
  }, [page, rowsPerPage, params, order, orderBy, patients]); // eslint-disable-line react-hooks/exhaustive-deps

  const classes = useStyles();

  const patientsTable = () => (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell
                key={'PATIENTFIRSTNAME'}
                align="center"
                style={{ width: '140px' }}
                sortDirection={orderBy === 'PATIENTFIRSTNAME' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'PATIENTFIRSTNAME'}
                  direction={orderBy === 'PATIENTFIRSTNAME' ? order : 'desc'}
                  onClick={createSortHandler('PATIENTFIRSTNAME')}
                >
                  FIRST NAME
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'PATIENTLASTNAME'}
                align="center"
                style={{ width: '140px' }}
                sortDirection={orderBy === 'PATIENTLASTNAME' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'PATIENTLASTNAME'}
                  direction={orderBy === 'PATIENTLASTNAME' ? order : 'desc'}
                  onClick={createSortHandler('PATIENTLASTNAME')}
                >
                  LAST NAME
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'DISPENSARYNAME'}
                align="center"
                style={{ width: '160px' }}
                sortDirection={orderBy === 'DISPENSARYNAME' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'DISPENSARYNAME'}
                  direction={orderBy === 'DISPENSARYNAME' ? order : 'desc'}
                  onClick={createSortHandler('DISPENSARYNAME')}
                >
                  DISPENSARY
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'PATIENTPHONE'}
                align="center"
                style={{ width: '120px' }}
                sortDirection={orderBy === 'PATIENTPHONE' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'PATIENTPHONE'}
                  direction={orderBy === 'PATIENTPHONE' ? order : 'desc'}
                  onClick={createSortHandler('PATIENTPHONE')}
                >
                  PHONE
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'PATIENTEMAIL'}
                align="center"
                style={{ width: '140px' }}
                sortDirection={orderBy === 'PATIENTEMAIL' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'PATIENTEMAIL'}
                  direction={orderBy === 'PATIENTEMAIL' ? order : 'desc'}
                  onClick={createSortHandler('PATIENTEMAIL')}
                >
                  EMAIL
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'PATIENTMMCC'}
                align="center"
                style={{ width: '100px' }}
                sortDirection={orderBy === 'PATIENTMMCC' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'PATIENTMMCC'}
                  direction={orderBy === 'PATIENTMMCC' ? order : 'desc'}
                  onClick={createSortHandler('PATIENTMMCC')}
                >
                  MMCC
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                key={'COUNTS'}
                align="center"
                style={{ width: '80px' }}
                sortDirection={orderBy === 'COUNTS' ? order : false}
              >
                <TableSortLabel
                  active={orderBy === 'COUNTS'}
                  direction={orderBy === 'COUNTS' ? order : 'desc'}
                  onClick={createSortHandler('COUNTS')}
                >
                  CASE(s)
                  <span className={classes.visuallyHidden}>
                    {order === 'desc'
                      ? 'sorted descending'
                      : 'sorted ascending'}
                  </span>
                </TableSortLabel>
              </StyledTableCell>
              <StyledTableCell
                align="center"
                style={{ width: '80px' }}
              ></StyledTableCell>
            </TableRow>
          </TableHead>
          {patients && patients!.length > 0 && (
            <TableBody>
              {patients!.map((patient: any) => (
                <StyledTableRow key={`${patient.id}-${patient.dispensary.id}`}>
                  <StyledTableCell component="th" scope="row" align="center">
                    <span className="patients-table-data">
                      {patient.firstName || ''}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    <span className="patients-table-data">
                      {patient.lastName || ''}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span
                      className={`patients-table-data--dispensary background-color-${getDispensaryColor(
                        patient.dispensary,
                      )}`}
                    >
                      <Link to={`/dispensaries/${patient!.dispensary.id}`}>
                        {patient.dispensary.name.slice(0, 12)}
                      </Link>
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span className="patients-table-data">
                      {patient.phoneNumber}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span className="patients-table-data">
                      {patient.emailAddress}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span className="patients-table-data">
                      {patient.mmccNumber}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="center">
                    <span className="patients-table-data">
                      {patient.caseCount}
                    </span>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Link
                      className={`link-color-${getDispensaryColor(
                        patient.dispensary,
                      )}`}
                      to={`/patients/${patient.id}/dispensaries/${
                        patient.dispensary!.id
                      }`}
                    >
                      <FontAwesomeIcon icon={faExternalLinkAlt} />
                    </Link>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {patients ? (
        patients!.length > 0 ? (
          <TablePagination
            rowsPerPageOptions={[50, 100, 200]}
            component="div"
            count={counts}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        ) : (
          <div className="empty-table-message">No patients to display</div>
        )
      ) : (
        <div className="table-loading-container" style={{ marginLeft: '0' }}>
          <Spinner animation="border" />
        </div>
      )}

      <div>&nbsp;</div>
    </>
  );

  return (
    <div className="patients padding-left">
      <h2 className="patients-table-title">
        All Patients&nbsp;
        <ToggleButtonGroup
          size="small"
          value={showFilter}
          exclusive
          onChange={handleShowFilter}
        >
          <ToggleButton value={true}>
            <FilterListIcon fontSize="small" />
          </ToggleButton>
        </ToggleButtonGroup>
      </h2>
      {showFilter && (
        <Paper>
          <form className="p-1" noValidate autoComplete="off">
            <Grid container spacing={1}>
              <Grid item>
                <FormControl variant="filled">
                  <Dropdown
                    id="dispensaryId1"
                    onSelect={(e) => {
                      handleDropDowns('dispensary', e);
                    }}
                  >
                    <Dropdown.Toggle variant="outline-info" id="dropdown-basic">
                      {dispensaryName}
                      {` `}
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      {dispensaries != undefined &&
                        dispensaries.length > 0 &&
                        dispensaries.map((dispensery) => {
                          return (
                            <Dropdown.Item
                              key={dispensery.name}
                              eventKey={dispensery.id}
                            >
                              {dispensery.name}
                            </Dropdown.Item>
                          );
                        })}
                    </Dropdown.Menu>
                  </Dropdown>
                </FormControl>
              </Grid>
              <Grid item className="alignItem">
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon1">
                      {/* First Name  */}
                      <FontAwesomeIcon icon={faSearch} size="sm" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl>
                    <Form.Control
                      type="text"
                      id="inputGroup-sizing-sm"
                      placeholder="First Name"
                      aria-label="First Name"
                      aria-describedby="basic-addon1"
                      value={inputs.patientFirstName}
                      name={'patientFirstName'}
                      onChange={handleChangInput}
                    />
                  </FormControl>
                </InputGroup>
              </Grid>
              <Grid item className="alignItem">
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon2">
                      {/* First Name  */}
                      <FontAwesomeIcon icon={faSearch} size="sm" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl>
                    <Form.Control
                      type="text"
                      id="inputGroup-sizing-sm"
                      placeholder="Last Name"
                      aria-label="Last Name"
                      aria-describedby="basic-addon2"
                      value={inputs.patientLastName}
                      name={'patientLastName'}
                      onChange={handleChangInput}
                    />
                  </FormControl>
                </InputGroup>
              </Grid>
              <Grid item className="alignItem">
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon3">
                      {/* First Name  */}
                      <FontAwesomeIcon icon={faSearch} size="sm" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl>
                    <Form.Control
                      type="text"
                      id="inputGroup-sizing-sm"
                      placeholder="Patient phone"
                      aria-label="Patient phone"
                      aria-describedby="basic-addon3"
                      value={inputs.patientPhone}
                      name={'patientPhone'}
                      onChange={handleChangInput}
                    />
                  </FormControl>
                </InputGroup>
              </Grid>
              <Grid item className="alignItem">
                <InputGroup className="mb-3" size="sm">
                  <InputGroup.Prepend>
                    <InputGroup.Text id="basic-addon3">
                      {/* First Name  */}
                      <FontAwesomeIcon icon={faSearch} size="sm" />
                    </InputGroup.Text>
                  </InputGroup.Prepend>
                  <FormControl>
                    <Form.Control
                      type="email"
                      id="inputGroup-sizing-sm"
                      placeholder="Patient Email"
                      aria-label="Patient Email"
                      aria-describedby="basic-addon3"
                      value={inputs.patientEmail}
                      name={'patientEmail'}
                      onChange={handleChangInput}
                    />
                  </FormControl>
                </InputGroup>
              </Grid>
              <Button
                variant="outline-info"
                size="sm"
                className="btnCustom"
                onClick={handleSubmit}
              >
                Search
              </Button>
              &nbsp;&nbsp;&nbsp;
              <Button
                variant="outline-dark"
                size="sm"
                className="btnCustom"
                onClick={handleReset}
              >
                RESET
              </Button>
            </Grid>
          </form>
        </Paper>
      )}
      <div></div>
      {patientsTable()}
    </div>
  );
});

export default ManagePatients;

import React from 'react';

const DotGrid = () => {
  return (
    <div className="grid-box">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  );
};

const CannovateBanner = () => {
  return (
    <div className="cannovate-banner">
      <div className="flex-1">
        <DotGrid />
      </div>
      <div>
        <img src="/images/cannovate-logo.png" alt="cannovate" />
      </div>
      <div className="flex-1 d-flex justify-content-end align-items-end">
        <DotGrid />
      </div>
    </div>
  );
};

export default CannovateBanner;

import React, { useEffect } from 'react';
import { Tab, Tabs, Spinner } from 'react-bootstrap';
import { Case } from '../../models/cases';
import PatientInfo from './PatientInfo';
import DispensaryInfo from '../Dispensaries/DispensaryInfo';
import { searchCases } from '../../services/case.service';

type CaseDetailProps = {
  match: {
    params: {
      caseNumber: number;
      dispensaryId: number;
    };
  };
};

const CaseDetails = React.memo<CaseDetailProps>((props) => {
  const [contactCase, setContactCase] = React.useState<Case>();

  const getCase = async () => {
    const res = await searchCases({
      caseId: props.match.params.caseNumber,
    });
    setContactCase(res);
  };

  useEffect(() => {
    getCase();
  }, [props.match]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="margin-left">
      {contactCase ? (
        <>
          <div className="calldetails-slider--grid">
            <Tabs defaultActiveKey="patient" id="call-details">
              <Tab eventKey="patient" title="Patients Details">
                <PatientInfo patient={contactCase.patient} />
              </Tab>
              <Tab
                eventKey="dispensary"
                title={`${contactCase.dispensary.name} Profile`}
              >
                <DispensaryInfo dispensary={contactCase.dispensary} />
              </Tab>
              <Tab eventKey="patientCases" title="Related Cases"></Tab>
            </Tabs>
          </div>
        </>
      ) : (
        <Spinner animation="border" />
      )}
    </div>
  );
});

export default CaseDetails;

import cleanDeep from 'clean-deep';
import axios from 'axios';
import { apiUrl } from '../config/environments';
import { Product, Promotion } from '../models/products';

export interface ProductRequest {
  dispensaryId?: number;
  productId?: number;
  dispensaryName?: string;
  productName?: string;
  productType?: string;
  brand?: string;
  quantity?: string;
  vendor?: string;
  cbd?: string;
  thc?: string;
  sellingPrice?: string;
  stock?: boolean;

  getCount?: boolean;
  offset?: number;
  limit?: number;
  order?: string;
  orderBy?: string;
}

//TODO remove during actual implementation

async function readProductsJSON() {
  const jsonObj = require('../productsData.json');
  console.log('JSON', jsonObj);
  let data = jsonObj.data;
  for (var i in data) {
    const desp = {
      id: 4,
      contactCenterPhoneNumber: '123456',
      name: 'Curaleaf',
    };
    data[i].dispensary = desp;
  }

  return {
    count: 10,
    records: data,
  };
}
//remove till here

export const searchProducts = async (params: ProductRequest): Promise<any> => {
  return readProductsJSON();
  // const res = await axios.get(`${apiUrl}/products`, {
  //   params: {
  //     ...cleanDeep(params),
  //   },
  // });
  // return res.data;
};

export const reserveProducts = async (reservationData) => {
  return new Promise(async (resolve, reject) => {
    try {
      console.log(
        'Received Props Case: :::: Making Reservations',
        reservationData,
      );
      await axios.post(`${apiUrl}/reservations`, reservationData);
    } catch (err) {
      reject(err);
    } finally {
      console.log('Reservation Successful');
      resolve(true);
    }
  });
};

export const updateProduct = async (
  dispensaryId: number,
  updatedProduct: Product,
): Promise<any> => {
  try {
    const product = {
      ...updatedProduct,
    };
    product.dispensary = undefined;

    const response = await axios.put(
      `${apiUrl}/dispensaries/${dispensaryId}/products/${updatedProduct.id}?db=MySQL`,
      cleanDeep(product),
    );

    return response.data;
  } catch (err) {}
};

export const deleteProduct = async (
  dispensaryId: number,
  productId: number,
) => {
  return new Promise<void>(async (resolve) => {
    await axios.delete(
      `${apiUrl}/dispensaries/${dispensaryId}/products/${productId}?db=mysql`,
    );
    resolve();
  });
};

export const fetchAllPromotions = () => {
  return new Promise<Array<Promotion>>(async (resolve, reject) => {
    try {
      const res = await axios.get(`${apiUrl}/reservations/promotions`);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const updateReservations = (reservationData) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await axios.patch(`${apiUrl}/reservations`, reservationData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchActiveReservations: any = (patientId) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await axios.put(`${apiUrl}/reservations`, { patientId });
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchReservationsByDate = (dataToSearch) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await axios.post(
        `${apiUrl}/reservations/dispensaryrepresentative`,
        { dataToSearch },
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchAllReservations: any = (period = 'Future') => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await axios.post(`${apiUrl}/reservations/promotions`, {
        toSearch: period,
      });
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchPastReservations: any = (dataToSearch) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await axios.put(
        `${apiUrl}/reservations/dispensaryrepresentative`,
        { dataToSearch },
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const processReservation = async (reservationData) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const res = await axios.patch(`${apiUrl}/reservations/`, reservationData);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const rollbackReservation = async (reservationId) => {
  return new Promise<void>(async (resolve, reject) => {
    try {
      const reservationData = {
        reservationId,
      };
      const res = await axios.patch(
        `${apiUrl}/reservations/dispensaryrepresentative`,
        reservationData,
      );
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

import React, { useContext, useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import CallQueue from './CallQueue/CallQueue';
import QueueCaseList from './Queue.CaseList';
import { Card, Spinner } from 'react-bootstrap';
import {
  AuthContext,
  ContactContext,
  SidePanelContext,
} from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import { CaseContext, CaseSkill } from '../../models/cases';
import { caseContext as _caseContext } from '../../context/case/caseContext';
import { sidePanelContext as _sidePanelContext } from '../../context/sidePanel/sidePanelContext';
import '../../assets/css/dashboard.scss';
import { faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { printDesignation } from '../../models/utils';
import { contactContext as _contactContext } from '../../context/contact/contactContext';

const Dashboard = () => {
  const contactContext: ContactContext = useContext(_contactContext);

  const authContext: AuthContext = useContext(_authContext);
  const { user } = authContext;
  const caseContext: CaseContext = useContext(_caseContext);
  const getCases = caseContext.getCases!;
  const sidePanelContext: SidePanelContext = useContext(_sidePanelContext);
  const { agentStatus } = sidePanelContext;
  const [displayWarning, setdisplayWarning] = useState(true);

  const [agentLoggedStatus, setagentLoggedStatus] = useState('');
  const [displaySpinner, setDisplaySpinner] = useState(false);
  const [agentStatusSwitch, setAgentStatusSwitch] = useState(false);

  const renderPhone = contactContext.renderPhone!;
  const { showCall, renderCall } = contactContext;

  useEffect(() => {
    if (user) {
      getCases(user!.groups[0]);
    }
    const interval = setInterval(() => getCases(user!.groups[0]), 3000);
    return () => {
      clearInterval(interval);
    };
  }, [user]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (agentStatus) {
      setdisplayWarning(false);
    }
  }, [agentStatus]);

  useEffect(() => {
    if (agentStatus === 'Offline') {
      setagentLoggedStatus('Available');
    } else {
      setagentLoggedStatus(agentStatus!);
    }

    if (agentStatus) {
      setDisplaySpinner(false);
    }
  }, [agentStatus]);

  if (user && user!.groups.includes(CaseSkill.DR)) {
    console.log('User is a DR');
    return <Redirect to="/reservations" />;
  }

  const handleReload = () => {
    if (renderCall) {
      window.location.reload();
    }
  };

  const handleAgentOnlineChange = () => {
    if (!renderCall) {
      setDisplaySpinner(true);
      renderPhone();
    }
  };

  return (
    <div
      className="dashboard"
      style={{ maxWidth: showCall ? 'calc(100vw - 16rem)' : '100vw' }}
    >
      {displayWarning ? (
        <div className="offline-message" style={{ marginTop: '2rem' }}>
          <Card style={{ width: '100%' }}>
            <Card.Body style={{ color: 'red', borderRadius: '5px' }}>
              <p style={{ padding: '5px' }}>
                {' '}
                You must login to AWS Connect By clicking on the Online/Offline
                Toggle{' '}
              </p>
            </Card.Body>
          </Card>
        </div>
      ) : null}

      {
        <div className="dashboard-sidepanel--user mobile-side-panel">
          {user &&
            (user!.groups.includes(CaseSkill.CSR) ||
              user!.groups.includes(CaseSkill.CD)) && (
              <div className="d-flex flex-column pr-3 pl-3">
                <div className="agent-online-status d-flex justify-content-between mb-3">
                  <h6 className="sidepanel-agentstatus">Agent status: </h6>
                  <div className="d-flex flex-column">
                    <div
                      className={`badge text-wrap mb-2 badge-${
                        agentStatusSwitch || renderCall ? 'success' : 'danger'
                      }`}
                    >
                      {agentStatusSwitch || renderCall ? 'Online' : 'Offline'}
                    </div>

                    <label
                      htmlFor="online"
                      className={`ml-0 mb-0 switch agent-online-${agentStatusSwitch}`}
                      onClick={
                        renderCall
                          ? handleReload
                          : () => setAgentStatusSwitch(!agentStatusSwitch)
                      }
                    >
                      <input
                        type="checkbox"
                        checked={agentStatusSwitch || renderCall}
                        className={`ml-0 mb-0 switch agent-online-${agentStatusSwitch}`}
                      />
                      <span className="slider round" />
                    </label>
                  </div>
                </div>
                <div className="justify-content-between d-flex align-items-center">
                  <h6 className="sidepanel-agentstatus">AWS Connect</h6>
                  <h6
                    className="sidepanel-agentstatus"
                    onClick={
                      renderCall ? handleReload : handleAgentOnlineChange
                    }
                  >
                    <button
                      style={{
                        background: 'transparent',
                        outline: 'transparent',
                        border: 'transparent',
                      }}
                      onClick={handleReload}
                    >
                      <FontAwesomeIcon
                        icon={faPowerOff}
                        style={{ width: '3rem', height: '1.25rem' }}
                        className={`agent-online-${renderCall}`}
                      />
                    </button>
                  </h6>
                </div>
              </div>
            )}
          {!!user && user.groups && (
            <div className="dashboard-sidepanel--user-image">
              <img
                src="/images/cannovate-profile.png"
                style={{
                  height: '5rem',
                  width: '5rem',
                  color: '#ccc',
                  border: '1px solid #75D317',
                }}
                alt="display picture"
              />
            </div>
          )}
          <div>
            {/* Only For Dev Env */}
            {displaySpinner ? (
              <Spinner animation="border" />
            ) : agentLoggedStatus ? (
              <span className="sidepanel-agentstatus">
                Call Status : {agentLoggedStatus}
              </span>
            ) : null}
            {/* Only For Dev Env */}
          </div>
          <div style={{ marginBottom: '15px' }}>
            {!!user && user.agentname && (
              <span className="sidepanel-agentname">
                {/* {printName} */}
                {user!.agentname}
              </span>
            )}
            {!!user && user.groups && (
              <>
                <span className="font-weight-bold">{user!.email}</span>
                <br />
                <span className="sidepanel-agentroll">
                  {printDesignation(user!.groups)}
                </span>
              </>
            )}
          </div>
        </div>
      }

      <CallQueue />

      <div className="dashboard-cases">
        <QueueCaseList caseType="allCases" />
      </div>
    </div>
  );
};

export default Dashboard;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as AmazonCognitoIdentity from 'amazon-cognito-identity-js';
import envVariables from '../../config/cognito';
import { Toast } from 'react-bootstrap';
import '../../assets/css/login.scss';
import CannovateBanner from './CannovateBanner';

const ForgotPassword = (props) => {
  const [email, setEmail] = useState('');
  const [rCode, setrCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [successToast, setSuccessToast] = useState(false);
  const [failureToast, setFailureToast] = useState(false);
  const [warningToast, setWarningToast] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onChangeInput = (e) => {
    switch (e.target.name) {
      case 'email':
        setEmail(e.target.value);
        break;
      case 'rCode':
        setrCode(e.target.value);
        break;
      case 'password':
        setPassword(e.target.value);
        break;
      case 'confirmPassword':
        setConfirmPassword(e.target.value);
        break;
    }
  };
  const handleSubmit = (e) => {
    setSuccessToast(false);
    setFailureToast(false);
    setWarningToast(false);
    e.preventDefault();
    if (rCode !== '' && password === confirmPassword) {
      const validPass = new RegExp(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/gm,
      ).test(password);
      if (!validPass) {
        setWarningToast(true);
        return;
      }
      const userPool = new AmazonCognitoIdentity.CognitoUserPool({
        UserPoolId: envVariables.Auth.userPoolId, // replace by yours
        ClientId: envVariables.Auth.userPoolWebClientId, // replace by yours
      });

      const userData = {
        Username: email,
        Pool: userPool,
      };

      const cognitoUser = new AmazonCognitoIdentity.CognitoUser(userData);
      cognitoUser.confirmPassword(rCode, password, {
        onFailure(err) {
          setErrorMessage(err.message);
          setFailureToast(true);
          setrCode('');
          setEmail('');
          setPassword('');
          setConfirmPassword('');
        },
        onSuccess() {
          setSuccessToast(true);
          setTimeout(() => props.history.push('/'), 3000);
        },
      });
    } else {
      setWarningToast(true);
    }
  };
  const logo = <img src="/images/logo_2019-10-01/logo@3x.png" alt="logo" />;
  return (
    <div id="app-login" className="app-login">
      <CannovateBanner />
      <div className="app-login-wrapper" style={{ position: 'relative' }}>
        <div className="login-form-top">
          {/* {logo} */}
          <span>Forgot Password ?</span>
        </div>
        <div className="login-form-bottom">
          <form id="reset-password-form">
            <div className="form-group">
              <div style={{ display: successToast ? 'block' : 'none' }}>
                <Toast
                  className="login-input"
                  style={{
                    backgroundColor: 'green',
                    color: 'white',
                    maxWidth: '100%',
                  }}
                  autohide={true}
                  show={successToast}
                  delay={1500}
                >
                  <Toast.Body style={{ width: '100%' }}>
                    Password reset successful!
                  </Toast.Body>
                </Toast>
              </div>
              <div style={{ display: failureToast ? 'block' : 'none' }}>
                <Toast
                  className="login-input"
                  style={{
                    backgroundColor: '#f8d7da',
                    color: '#721c24',
                    maxWidth: '100%',
                    height: 'unset',
                  }}
                  autohide={true}
                  show={failureToast}
                  delay={1500}
                >
                  <Toast.Body style={{ width: '100%' }}>
                    Password reset error!
                    <ul
                      style={{ marginBottom: 'unset', listStyleType: 'bullet' }}
                    >
                      <li>
                        {`\u2022`} {errorMessage}
                      </li>
                    </ul>
                  </Toast.Body>
                </Toast>
              </div>
              <div style={{ display: warningToast ? 'block' : 'none' }}>
                <Toast
                  className="login-input"
                  style={{
                    backgroundColor: '#fed867',
                    color: '#721c24',
                    maxWidth: '100%',
                    height: 'unset',
                    textAlign: 'left',
                  }}
                  autohide={true}
                  show={warningToast}
                  delay={1500}
                >
                  <Toast.Body style={{ width: '100%' }}>
                    Invalid Data!
                    <ul
                      style={{ marginBottom: 'unset', listStyleType: 'bullet' }}
                    >
                      <li>
                        {`\u2022`} Password Reset Code must be a 6 digit number.
                      </li>
                      <li>
                        {`\u2022`} Password must be atleast 12 characters long.
                      </li>
                      <li>
                        {`\u2022`} Password must have atleast
                        <ul>
                          <li>&emsp;{`\u2022`} 1 Uppercase character</li>
                          <li>&emsp;{`\u2022`} 1 Lowercase character</li>
                          <li>&emsp;{`\u2022`} 1 special character</li>
                          <li>&emsp;{`\u2022`} 1 number</li>
                        </ul>
                      </li>
                      <li>
                        {`\u2022`} Password and Confirm Password must match
                      </li>
                    </ul>
                  </Toast.Body>
                </Toast>
              </div>
              <label htmlFor="Enter Email">
                <span className="login-label">Enter Email</span>
                <input
                  className="login-input"
                  type="email"
                  name="email"
                  value={email}
                  onChange={onChangeInput}
                  required
                />
              </label>
              <label htmlFor="Enter Password Reset Code">
                <span className="login-label">Enter Password Reset Code</span>
                <input
                  className="login-input"
                  type="number"
                  name="rCode"
                  value={rCode}
                  onChange={onChangeInput}
                  required
                />
              </label>
              <label htmlFor="Enter new password">
                <span className="login-label">Enter new password</span>
                <input
                  className="login-input"
                  type="password"
                  name="password"
                  value={password}
                  onChange={onChangeInput}
                  required
                />
              </label>
              <label htmlFor="Confirm new password">
                <span className="login-label">Confirm new password</span>
                <input
                  className="login-input"
                  type="password"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={onChangeInput}
                  required
                />
              </label>
            </div>
            <input
              type="submit"
              value="Submit"
              className="login-button"
              onClick={handleSubmit}
            />
          </form>
        </div>
        <Link className="forgot-password" to="/">
          Login
        </Link>
        <Link
          className="forgot-password"
          to="/forgot-password"
          style={{ float: 'left' }}
        >
          Forgot Password
        </Link>
      </div>
    </div>
  );
};

export default ForgotPassword;

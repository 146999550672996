import React, { useState, useContext, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import { Spinner } from 'react-bootstrap';
import '../../assets/css/login.scss';
import CannovateBanner from './CannovateBanner';

const Login = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { isAuthenticated, resetPasswordRequired, errorMsg } = authContext;
  const login = authContext.login!;
  const resetPassword = authContext.resetPassword || (() => {});
  const logo = <img src="/images/logo_2019-10-01/logo@3x.png" alt="logo" />;

  const [user, setUser] = useState({
    email: '',
    password: '',
    newPassword: '',
  });

  const [displayError, setDisplayError] = useState(errorMsg);

  const { email, password, newPassword } = user;

  //Loader onclick
  const [showLoadspinner, setShowLoadspinner] = useState(false);
  const LoginLoader = () => (
    <>
      <button className="login-button" disabled>
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
        <Spinner
          as="span"
          animation="grow"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </button>
    </>
  );

  useEffect(() => {
    setDisplayError(errorMsg);
  }, [errorMsg]);

  const loginSubmit = async (evt) => {
    evt.preventDefault();
    login(email, password);
    setShowLoadspinner(true);
    //For Testing static timeout will make dynamic
    setTimeout(function() {
      setShowLoadspinner(false);
    }, 3000);
  };

  const onChange = (e) => {
    setDisplayError('');
    setUser({ ...user, [e.target.name]: e.target.value });
  };

  const resetPasswordSubmit = async (evt) => {
    evt.preventDefault();
    resetPassword(email, password, newPassword);
  };

  const renderRedirect = () => {
    if (isAuthenticated && !resetPasswordRequired) {
      return <Redirect to="/dashboard" />;
    }
    return null;
  };
  return (
    <div id="app-login" className="app-login">
      <CannovateBanner />
      <div className="app-login-wrapper">
        {renderRedirect()}
        {!isAuthenticated ? (
          <div className="login-form-top">
            <h1 style={{ fontSize: '1.5rem' }}>Login</h1>
            <h4>Welcome back, please login</h4>
            {displayError ? (
              <div
                className="alert alert-danger fade show round-border"
                role="alert"
              >
                {displayError}
              </div>
            ) : null}
          </div>
        ) : null}
        {!isAuthenticated ? (
          <div className="login-form-bottom">
            <form onSubmit={loginSubmit}>
              <div className="form-group">
                <label htmlFor="Sign in with your e-mail address">
                  <span className="login-label">
                    Sign in with your e-mail address
                  </span>
                  <input
                    className="login-input"
                    id="email-address"
                    type="email"
                    name="email"
                    value={email}
                    onChange={onChange}
                    required
                  />
                </label>
              </div>
              <div className="form-group">
                <label htmlFor="password">
                  <span className="login-label">Password</span>
                  <input
                    className="login-input"
                    id="password"
                    type="password"
                    name="password"
                    value={password}
                    onChange={onChange}
                    required
                  />
                </label>
              </div>
              {showLoadspinner ? (
                <LoginLoader />
              ) : (
                <input
                  id="login-submit"
                  type="submit"
                  value="Sign in to Dashboard"
                  className="login-button"
                />
              )}
            </form>
          </div>
        ) : null}

        {isAuthenticated && resetPasswordRequired ? (
          <div className="login-form-top">
            {logo}
            <span>Please update your password!</span>
          </div>
        ) : null}
        {isAuthenticated && resetPasswordRequired ? (
          <div className="login-form-bottom">
            <form onSubmit={resetPasswordSubmit}>
              <label htmlFor="newPassword">
                <span className="login-label">Reset Password</span>
                <input
                  className="login-input"
                  type="password"
                  name="newPassword"
                  value={newPassword}
                  onChange={onChange}
                  required
                />
                <input
                  type="submit"
                  value="Submit New Password"
                  className="login-button"
                />
              </label>
            </form>
          </div>
        ) : null}
        <Link className="forgot-password" to="/forgot-password/">
          Forgot Password?
        </Link>
      </div>
    </div>
  );
};

export default Login;

import React, { useContext } from 'react';
import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';

const Settings = () => {
  const authContext: AuthContext = useContext(_authContext);
  const logout = authContext.logout || (() => { });
  return (
    <div>
      <button
        className="logout-button"
        type="button"
        onClick={() => logout()}
      >
        LogOut
      </button>
    </div>
  );
};

export default Settings;

import React from 'react';
import { Spinner } from 'react-bootstrap';

const Knowledge = () => (
  <div style={{ textAlign: 'center', marginLeft: '50%', marginTop: '10%' }}>
    <h3>Knowledge</h3>
    <Spinner animation="grow" variant="primary" />
    <Spinner animation="grow" variant="success" />
    <Spinner animation="grow" variant="danger" />
    <br />
    <i>Coming Soon...</i>
  </div>
);

export default Knowledge;

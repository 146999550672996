/* eslint-disable import/prefer-default-export */

import { Address } from './common';

export interface DispensaryContext {
  dispensaries?: Array<Dispensary>;
  dispensary?: Dispensary;
  getDispensaries?: Function;
  getDispensary?: Function;
}

export interface DispensaryCreate {
  id?: number;
  name: string;
  phoneNumber?: string;
  contactCenterPhoneNumber: string;
  contactCenterEmail?: string;
  address?: Address;
  websiteUrl?: string;
}

export interface Dispensary extends DispensaryCreate {
  id: number;
  caseCount?: number;
  companyLogoUrl?: string;
}

export function getDispensaryColor(dispensary: Dispensary): string {
  const value = parseInt(dispensary.contactCenterPhoneNumber.substr(-1), 10);
  const colorHash = {
    0: '18a5d6',
    1: '89d011',
    2: '1bb934',
    3: '0ccff7',
    4: '437c10',
    5: '0ccff7',
    6: '319145',
    7: '18a5d6',
    8: '18a5d6',
    9: '89d011',
  };
  return colorHash[value];
}

import {
  ConnectClient,
  AssociateApprovedOriginCommand,
} from '@aws-sdk/client-connect';

declare global {
  interface Window {
    connect: any;
    isTransfer: boolean;
  }
}

console.log('Connect Objet', window.connect);
export default window.connect;

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import { sidePanelContext as SidePanelContext } from './sidePanelContext';
import sidePanelReducer from './sidePanelReducer';
import {AGENT_STATUS } from '../types';

const AgentState = (props) => {
  const initialState = {
    agentState: null,
    agentStatus : null
  };


  const [state, dispatch] = useReducer(sidePanelReducer, initialState);

  const agentStatusToggle = (agentState : String, agentStatus : String) => {
      if(agentState === "routable"){
          // Available
        dispatch({
            type: AGENT_STATUS,
            payload: {agentState : true, agentStatus},
          });
      }else if (agentState === "offline"){
          // Offline    
        dispatch({
            type: AGENT_STATUS,
            payload: {agentState : false, agentStatus},
          });
      }else if (agentState === "system"){
          // CallingCustomer
          // On Pickup : Busy
          // AfterCallWork
        dispatch({
            type: AGENT_STATUS,
             payload:{agentState : true, agentStatus},
          });
      }
    
  };

  return (
    <SidePanelContext.Provider
      value={{
        agentState: state.agentState,
        agentStatus: state.agentStatus,
        agentStatusToggle
      }}
    >
      {props.children}
    </SidePanelContext.Provider>
  );
};

export default AgentState;

AgentState.propTypes = {
  children: PropTypes.shape({
  }),
};

AgentState.defaultProps = {
  children: {},
};

import React, { useReducer, useCallback,useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Auth } from 'aws-amplify';
import { authContext as AuthContext } from './authContext';
import authReducer from './authReducer';
import setAuthToken from '../../config/axios';

import { apiUrl } from '../../config/environments';

import {
  GET_ME_SUCCESS,
  GET_ME_FAIL,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  LOGOUT,
  MESSAGE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
} from '../types';

const AuthState = (props) => {
  const initialState = {
    token: localStorage.getItem('token'),
    refreshToken: localStorage.getItem('refreshToken'),
    isAuthenticated: null,
    loading: true,
    user: null,
    resetPasswordRequired: false,
    messages: [],
    errorMsg : null,
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  const loadUser = useCallback(async () => {
    if (localStorage.token) {
      setAuthToken(localStorage.token);
    }

    try {
      const res = await axios.get(`${apiUrl}/me`);
      dispatch({
        type: GET_ME_SUCCESS,
        payload: res.data,
      });
    } catch (err) {
      dispatch({
        type: GET_ME_FAIL,
      });
    }
  },[]);

  const resetPassword = useCallback(async (email, password, newPassword) => {
    try {
      // TODO wtf why do we have to sign in again
      Auth.signIn(email, password).then(
        async (user) => {
          const cognitoAuth = await Auth.completeNewPassword(
            user,
            newPassword,
            {},
          );
          dispatch({
            type: RESET_PASSWORD_SUCCESS,
            payload: cognitoAuth,
          });
          loadUser();
        },
        (err) => {
          throw new Error(err);
        },
      );
    } catch (err) {
      dispatch({
        type: RESET_PASSWORD_FAIL,
        payload: err.message,
      });
    }
  },[loadUser]);

  const login = useCallback(async (username, password) => {
    try {
      const cognitoAuth = await Auth.signIn({
        username,
        password,
      });
      dispatch({
        type: LOGIN_SUCCESS,
        payload: cognitoAuth,
      });
      if (cognitoAuth.challengeName !== 'NEW_PASSWORD_REQUIRED') {
        loadUser();
      }
    } catch (err) {
      dispatch({
        type: LOGIN_FAIL,
        payload: err.message,
      });
    }
  },[loadUser]);

  const logout = useCallback(() => dispatch({ type: LOGOUT }),[]);

  const addMessage = useCallback((message) => dispatch({ type: MESSAGE, payload: message }),[]);

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        refreshToken: state.refreshToken,
        isAuthenticated: state.isAuthenticated,
        resetPasswordRequired: state.resetPasswordRequired,
        loading: state.loading,
        user: state.user,
        loadUser,
        login,
        logout,
        resetPassword,
        addMessage,
        messages: state.messages,
        errorMsg : state.errorMsg,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;

AuthState.propTypes = {
  children: PropTypes.shape({}),
};

AuthState.defaultProps = {
  children: {},
};

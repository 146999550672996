import React, { useContext } from 'react';
import { ContactContext } from '../../models/common';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import Email from './Email';

const EmailContainer = () => {
  const contactContext: ContactContext = useContext(_contactContext);
  const { renderEmail } = contactContext;
  return renderEmail ? <Email /> : null;
};

export default EmailContainer;

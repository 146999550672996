import {
  GET_CASES_SUCCESS, GET_CASES_FAIL, GET_DISPENSARY_CASES_SUCCESS, GET_TOPICS, GET_PATIENT_CASSES_SUCCESS,  CLEAR_CASES,
} from '../types';


export default (state, action) => {
  switch (action.type) {
    case GET_CASES_SUCCESS:
      return {
        ...state,
        allCases: action.payload.allCases,
        pendingCases: action.payload.pendingCases,
        outboundCases: action.payload.outboundCases,
        inProgressCases: action.payload.inProgressCases,
        dispensaryCases: action.payload.dispensaryCases,
      };
    case GET_DISPENSARY_CASES_SUCCESS:
      return {
        ...state,
        dispensaryCases: action.payload.dispensaryCases,
      }
    case GET_PATIENT_CASSES_SUCCESS:
      return {
        ...state,
        patientCases: action.payload
      }
    case GET_CASES_FAIL:
      return {
        ...state,
      };
    case GET_TOPICS:
      return {
        ...state,
        allTopics: action.payload,
      };
    case CLEAR_CASES:
      return {
        ...state,
        allCases: [],
        pendingCases: [],
        outboundCases: [],
        inProgressCases: [],
        dispensaryCases: [],
        patientCases: [],
      };
    default:
      return state;
  }
};

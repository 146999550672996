/* eslint-disable import/prefer-default-export */

import { User } from './users';
import { Case } from './cases';

export interface AuthContext {
  isAuthenticated?: string;
  loading?: string;
  login?: Function;
  logout?: Function;
  loadUser?: Function;
  user?: User;
  resetPassword?: Function;
  resetPasswordRequired?: boolean;
  messages?: string[];
  addMessage?: Function;
  errorMsg?: string;
}

export interface ContactContext {
  call?: any;
  contactCase?: Case;
  renderCall?: boolean;
  initCall?: Function;
  showCall?: number;
  getCallData?: Function;
  completeContact?: Function;
  updateCallStatus?: Function;
  updateContactPatient?: Function;
  markDuplicate?:Function;
  isTransfer?: boolean;
  renderPhone?: Function;
  renderEmail?: boolean;
  initEmail?: Function;
  showEmail?: boolean;
  emailCase?: Case;
  sendEmail?: Function;
  contactError?: string;
  clearError?: Function;
  setContactCase?: Function;
  saveCase?: Function;
  onGoingCallToggle?:Function;
  onGoingCall?: boolean;
}

export interface Address {
  addressLine1?: string;
  addressLine2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  country?: string;
}

export interface OutboundEmail {
  subject: string;
  bodyHtml: string;
}


export interface CountType {
  SetInProgCase?: any;
  SetOutBoundCase?: any;
}

export interface SidePanelContext {
  agentState?: string;
  agentStatus?: string;
  agentStatusToggle?: Function
}

export function formatPhoneNumber(phoneNumberString) {
  if (phoneNumberString && phoneNumberString.length === 12) {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      const intlCode = (match[1] ? '+1 ' : '');
      return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    return null;
  }
  return phoneNumberString || '';
}

export function removeFalsy(obj) {
  const newObj = {};
  Object.keys(obj).forEach((prop) => {
    if (obj[prop]) { newObj[prop] = obj[prop]; }
  });
  return newObj;
}


export interface HeadCell {
  id: string;
  width: number;
  label: string;
  numeric: boolean;
}

export type Order = 'asc' | 'desc';

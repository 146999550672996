import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
  useContext,
} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Tab, Tabs, Toast } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Gender, Patient, PatientType } from '../../models/patients';
import {
  AuthContext,
  ContactContext,
  formatPhoneNumber,
} from '../../models/common';
import { getValiadatedDate } from '../../models/utils';
import 'react-datepicker/dist/react-datepicker.css';
import {
  Case,
  CaseContext,
  CaseCreate,
  CaseSkill,
  CaseState,
  InboundChannel,
} from '../../models/cases';
import {
  createPatient,
  PatientData,
  updatePatient,
} from '../../services/patient.service';
import '../../assets/css/call-detail.scss';
import '../../assets/css/patient-detail.scss';
import { Spinner } from 'react-bootstrap';
import { setTimeout } from 'timers';
import { caseContext as _caseContext } from '../../context/case/caseContext';
import { authContext as _authContext } from '../../context/auth/authContext';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import { UserSummary } from '../../models/users';
import dispensaryContext from '../../context/dispensary/dispensaryContext';
import { dispensaries } from '../../config/dispenseries';
import { toast } from 'react-toastify';

const NewCase = () => {
  const caseContext: CaseContext = useContext(_caseContext);
  const authContext: AuthContext = useContext(_authContext);
  const contactContext: ContactContext = useContext(_contactContext);

  const { createCase } = caseContext;
  const { initCall, initEmail, renderCall } = contactContext;
  const { user } = authContext;

  const [showLoadspinner, setShowLoadspinner] = useState(false);
  const [mmccError, setMMCCerror] = useState('');
  const [contactError, setError] = useState();
  const [errorMsg, setErrorMsg] = useState('');
  const [mmccValid, setMMCCValid] = useState(false);
  const [patientData, setPatient] = useState<PatientData>({
    dispensaryId: 0,
  });
  const [isSaving, setSaving] = useState(false);
  const [isSaved, setSaved] = useState(false);

  const isValidPatientData = () => {
    if (
      patientData.dispensaryId == undefined ||
      patientData.dispensaryId == 0 ||
      patientData.dispensaryId == null
    ) {
      toast.warn('Please select a dispensary');
      return false;
    }
    if (patientData.patientType == undefined) {
      toast.warn('Please select the type of patient');
      return false;
    }
    if (patientData.phone == undefined && patientData.email == undefined) {
      toast.warn(
        'A patient must have a valid phone number or an email address',
      );
      return false;
    }
    return true;
  };

  const createNewCase = async (type) => {
    if (!isValidPatientData()) return;
    try {
      const patient: Patient = {
        firstName: patientData.firstName,
        lastName: patientData.lastName,
        emailAddress: patientData.email,
        phoneNumber: patientData.phone,
        caregiver: patientData.caregiver,
        gender: patientData.gender,
        patientType: patientData.patientType,
        dob: patientData.dob,
        dispensaryId: patientData.dispensaryId,
      };

      if (type == 'email' && patientData.email == undefined) {
        console.log(type, patientData);
        toast.warning('Patient does not have an email address');
        return;
      } else if (type == 'phone' && patientData.phone == undefined) {
        console.log(type, patientData);
        toast.warning('Patient does not have a phone number');
        return;
      }

      if (!isSaved) {
        try {
          setSaving(true);
          const { patientId } = await createPatient(patientData);
          patient.id = patientId;
          setSaved(true);
        } catch (e) {
          toast.error(e);
          setSaved(false);
          return;
        } finally {
          setSaving(false);
        }
      }

      if (
        createCase !== undefined &&
        user != undefined &&
        initCall != undefined &&
        initEmail != undefined
      ) {
        const userSummary: UserSummary = {
          id: user.id || '0',
          name: user.agentname,
          email: 'admin.prod@cannovate.io',
          phoneNumber: user.phoneNumber || '+14453003677',
          groups: user.groups,
        };

        const caseInstance: CaseCreate = {
          inboundChannel: InboundChannel.EMAIL,
          inboundPhoneNumber: patient.phoneNumber, //'+12407207518',
          inboundEmailAddress: patient.emailAddress,
          assignedCSR: userSummary,
          skill: CaseSkill.CSR,
          state: CaseState.OUTBOUND,
        };

        const newCase = await createCase(patient.dispensaryId, caseInstance);
        newCase.outboundChannel = InboundChannel.EMAIL;
        newCase.logs = [
          {
            note: 'Email case created by CSR',
            skill: 'CSR',
            status: 'IN_PROGRESS',
            logType: 'SYSTEM',
            createdUser: user,
            createdUserId: user.id,
            createdDateTime: new Date(),
          },
        ];

        if (type == 'email' && patient.emailAddress != undefined) {
          await initEmail(newCase, user, 'outbound');
        } else if (patientData.phone != undefined) {
          await initCall(newCase, user, 'outbound');
        }
      } else {
        console.log(
          'Something is undefined',
          createCase,
          user,
          initCall,
          initEmail,
        );
      }
    } catch (e) {}
  };

  const onChangeCertDate = useCallback(
    (e) => setPatient({ ...patientData, certificateExpDate: e }),
    [patientData],
  );

  const onChangeMmccDate = useCallback(
    (e) => setPatient({ ...patientData, mmccExpDate: e }),
    [patientData],
  );

  const onChangeBirthDate = useCallback(
    (e) => setPatient({ ...patientData, dob: e }),
    [patientData],
  );

  const onChange = useCallback(
    (e) => {
      // if (props.onModified) props.onModified();

      // if ((e.target.name = 'mmccNumber')) {
      let vals = '';

      if (e.target.value.length > 0) {
        vals = e.target.value.toString().toUpperCase();
      }

      if (!new RegExp(/\w{4}-\w{4}-\w{4}-\w{4}/g).test(vals)) {
        setMMCCValid(false);
        setMMCCerror('');
      } else {
        setMMCCValid(true);
        console.log('Valie MMCC Number');

        // Loader will start spinning

        setShowLoadspinner(true);
        setTimeout(() => {
          setShowLoadspinner(false);
        }, 2000);

        if (vals == 'A8A8-A8A8-A8A8-A8A8') {
          setMMCCerror('Verified ✔️');
        } else if (vals == 'A8A8-A8A8-A8A8-A8A9') {
          setMMCCerror('METRC Servers are down');
        } else if (vals == 'A8A8-A8A8-A8A8-A8A7') {
          setMMCCerror('Expired ⚠️');
        } else if (vals == 'A8A8-A8A8-A8A8-A8A6') {
          setMMCCerror('Invalid ❌');
        }
      }
      setPatient({ ...patientData, [e.target.name]: vals });
      // }

      // TODO VALIDATE EMAIL AND PHONE
      setErrorMsg('');
      if (e.target.name === 'emailAddress') {
        if (
          !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/i).test(
            e.target.value,
          )
        ) {
          setErrorMsg('Invalid Email');
          //   toggleShowB();
        }
      } else if (e.target.name === 'phoneNumber') {
        console.log('Phone Number', e.target.value);
        if (
          new RegExp(
            /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
          ).test(e.target.value)
        ) {
          setErrorMsg('Invalid Phone');
          //   toggleShowB();
        }
      }
    },
    [patientData],
  );

  useEffect(() => {
    console.log('Patient Data', patientData);
  }, [patientData]);

  return (
    <div className="padding-left padding-left patient-parent">
      <Tabs defaultActiveKey="patient" id="call-details">
        <Tab eventKey="patient" title="Patient Details">
          <div className="outer-container">
            <div className="inner-container mb-0">
              <div className="left-style">
                <div className="name-inputs">
                  <div className="input-container">
                    First Name
                    <input
                      className="input-box"
                      type="text"
                      name="firstName"
                      value={patientData.firstName}
                      onChange={onChange}
                    />
                  </div>
                  <div className="input-container">
                    Last Name
                    <input
                      className="input-box"
                      type="text"
                      name="lastName"
                      value={patientData.lastName}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="input-container">
                  Email
                  <input
                    // disabled={isEmail}
                    className="input-box"
                    type="email"
                    name="email"
                    value={patientData.email}
                    onChange={onChange}
                  />
                </div>
                <div className="input-container">
                  Patient Type
                  <select
                    className="input-box dispensary-selector"
                    name="patientType"
                    onChange={onChange}
                  >
                    <option value="null">Select Type</option>
                    <option value="caregiver">Care Giver</option>
                    <option value="patient">Patient</option>
                  </select>
                </div>
                <div className="input-container">
                  Gender
                  <select
                    className="input-box dispensary-selector"
                    name="gender"
                    onChange={onChange}
                  >
                    <option value="null">Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                  </select>
                </div>
                <div className="input-container">
                  Telephone
                  <MaskedInput
                    mask={[
                      '+',
                      /[1-9]/,
                      ' ',
                      '(',
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    // disabled={isPhone}
                    className="input-box"
                    type="text"
                    name="phoneNumber"
                    // value={formatPhoneNumber(phoneNumber)}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="right-style">
                <div>
                  <div className="input-container">
                    Prescription #
                    <input
                      // TODO disabling auto complete is not working
                      autoComplete="off"
                      className="input-box"
                      type="text"
                      name="prescriptionNumber"
                      value={patientData.prescriptionNumber}
                      onChange={onChange}
                    />
                  </div>
                  {/* <span>{showLoadspinner ? <LoginLoader /> : mmccError}</span> */}
                </div>
                <div>
                  <div className="input-container">
                    MMCC #
                    <input
                      // TODO disabling auto complete is not working
                      autoComplete="off"
                      className="input-box"
                      type="text"
                      name="mmccNumber"
                      value={patientData.mmccNumber}
                      onChange={onChange}
                    />
                  </div>
                  {/* <span>{showLoadspinner ? <LoginLoader /> : mmccError}</span> */}
                </div>
                <div>
                  <div className="input-container input-container--date">
                    <p>MMCC # Exp Date</p>
                    <DatePicker
                      autoComplete="off"
                      selected={patientData.mmccExpDate}
                      onChange={onChangeMmccDate}
                      className="input-box"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
                <div>
                  <div className="input-container input-container--date">
                    <p>Certificate Exp Date</p>
                    <DatePicker
                      autoComplete="off"
                      selected={patientData.certificateExpDate}
                      onChange={onChangeCertDate}
                      className="input-box"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
                <div>
                  <div className="input-container input-container--date">
                    <p>Date of Birth</p>
                    <DatePicker
                      maxDate={new Date()}
                      autoComplete="off"
                      selected={patientData.dob}
                      onChange={onChangeBirthDate}
                      className="input-box"
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="case-action-bar">
              <select
                className="input-box dispensary-selector"
                name="dispensaryId"
                onChange={onChange}
              >
                <option value="null">Select Dispensary</option>
                {dispensaries.map((dispensary) => (
                  <option value={dispensary.id}>{dispensary.name}</option>
                ))}
              </select>
              {/* <button className="save-button">
                <FontAwesomeIcon icon={faSave} />
              </button> */}
              {!isSaved && (
                <Button onClick={createNewCase}>
                  {isSaving ? 'Saving...' : 'Save'}
                </Button>
              )}
              <Button onClick={() => createNewCase('phone')}>
                {isSaved ? 'Call' : 'Save and Call'}
              </Button>
              <Button onClick={() => createNewCase('email')}>
                {isSaved ? 'Email' : 'Save and Email'}
              </Button>
            </div>
          </div>
        </Tab>
      </Tabs>
    </div>
  );
};

export default NewCase;

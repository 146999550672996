import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { formatPhoneNumber } from '../../models/common';
import { getDispensaryColor } from '../../models/dispensaries';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { getDispensaries } from '../../services/dispensary.service';
import '../../assets/css/dispensaries.scss';

const DispensaryList = React.memo(() => {
  const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#858b8d',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  const useStyles = makeStyles({
    table: {
      minWidth: 700,
    },
  });

  const [dispensaries, setDispensaries] = React.useState();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const classes = useStyles();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const searchDispensaries = async () => {
    try {
      const res = await getDispensaries({ offset: 0, limit: 100 });
      setDispensaries(res);
    } catch (err) {}
  };

  useEffect(() => {
    searchDispensaries();
  }, [page, rowsPerPage]); // eslint-disable-line react-hooks/exhaustive-deps

  const dispensariesTable = () => (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell align="center">NAME</StyledTableCell>
              <StyledTableCell align="center">ADDRESS</StyledTableCell>
              <StyledTableCell align="center">PHONE</StyledTableCell>
              <StyledTableCell align="center">EMAIL</StyledTableCell>
              <StyledTableCell align="center">CASE(S)</StyledTableCell>
              <StyledTableCell align="center"></StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {dispensaries!.map((dispensary: any) => (
              <StyledTableRow key={dispensary.id}>
                <StyledTableCell
                  component="th"
                  scope="row"
                  style={{ width: '120px' }}
                  align="center"
                >
                  <span
                    className={`dispensaries-table-data--dispensary background-color-${getDispensaryColor(
                      dispensary,
                    )}`}
                    style={{
                      backgroundColor: `#${getDispensaryColor(dispensary)}`,
                    }}
                  >
                    {dispensary.name.slice(0, 12)}
                  </span>
                </StyledTableCell>
                <StyledTableCell style={{ width: '200px' }} align="center">
                  <span className={`dispensaries-table-data--name`}>
                    {dispensary.address.addressLine1}
                    <br />
                    {dispensary.address.city}, {dispensary.address.state}{' '}
                    {dispensary.address.zipCode}
                  </span>
                </StyledTableCell>
                <StyledTableCell style={{ width: '100px' }} align="center">
                  <span className={`dispensaries-table-data--name`}>
                    {formatPhoneNumber(dispensary.contactCenterPhoneNumber)}
                  </span>
                </StyledTableCell>
                <StyledTableCell style={{ width: '130px' }} align="center">
                  <span className={`dispensaries-table-data--name`}>
                    {dispensary.contactCenterEmail}
                  </span>
                </StyledTableCell>
                <StyledTableCell style={{ width: '80px' }} align="center">
                  <span className={`dispensaries-table-data--name`}>
                    {dispensary.caseCount || 0}
                  </span>
                </StyledTableCell>
                <StyledTableCell align="left" style={{ width: '80px' }}>
                  <Link
                    className={`link-color-${getDispensaryColor(dispensary)}`}
                    to={`/dispensaries/${dispensary.id}`}
                  >
                    <FontAwesomeIcon icon={faExternalLinkAlt} />
                  </Link>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[50, 100, 200]}
        component="div"
        count={dispensaries!.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      <div>&nbsp;</div>
    </>
  );
  return dispensaries ? (
    <div className="dispensaries padding-left">
      <h2>All Dispensaries</h2>
      {dispensariesTable()}
    </div>
  ) : (
    <div className="table-loading-container">
      <Spinner animation="border" />
    </div>
  );
});

export default DispensaryList;

import React, { useContext } from 'react';
import { ContactContext } from '../../models/common';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import SoftPhone from './SoftPhone';

const SoftPhoneContainer = () => {
  const contactContext: ContactContext = useContext(_contactContext);
  const { renderCall } = contactContext;
  return renderCall ? <SoftPhone /> : null;
};

export default SoftPhoneContainer;

import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Patient } from '../../models/patients';
import { patientContext as PatientContext } from './patientContext';
import patientReducer from './patientReducer';
import { GET_PATIENTS_SUCCESS, GET_PATIENTS_FAIL, GET_PATIENTS_BY_DISPENSARY } from '../types';

import { apiUrl } from '../../config/environments';

const PatientState = (props) => {
  const initialState = {
    patients: null,
  };

  const [state, dispatch] = useReducer(patientReducer, initialState);

  const getPatients = async (dispensaryId: number) => {
    try {

      const allPatients: { data: Patient[] } = await axios.get(`${apiUrl}/patients?db=mysql`);
      const patients : Patient[] = allPatients.data.sort((a, b) => (a.dispensary && b.dispensary) 
        ? a.dispensary.name.localeCompare(b.dispensary.name):0);

      dispatch({
        type: GET_PATIENTS_SUCCESS,
        payload: patients,
      });
    } catch (err) {
      dispatch({
        type: GET_PATIENTS_FAIL,
        payload: err.message,
      });
    }
  };
  const getPatientsByDispensary = async (dispensaryId: number) => {
    try {
      const patients = await axios.get(`${apiUrl}/dispensaries/${dispensaryId}/patients?db=mysql`);
      dispatch({
        type: GET_PATIENTS_BY_DISPENSARY,
        payload: patients.data,
      });
    } catch (err) {
      dispatch({
        type: GET_PATIENTS_FAIL,
        payload: err.message,
      });
    }
  };

  const deletePatient = async (dispensaryId: number, patientId: number) => {
    return new Promise(async (resolve) => {
      await axios.delete(`${apiUrl}/dispensaries/${dispensaryId}/patients/${patientId}?db=mysql`);
      resolve();
    })
  };

  return (
    <PatientContext.Provider
      value={{
        patients: state.patients,
        getPatients,
        getPatientsByDispensary,
        deletePatient,
      }}
    >
      {props.children}
    </PatientContext.Provider>
  );
};

export default PatientState;

PatientState.propTypes = {
  children: PropTypes.shape({
  }),
};

PatientState.defaultProps = {
  children: {},
};

import React from 'react';
import cleanDeep from 'clean-deep';
import axios from 'axios';
import { apiUrl } from '../config/environments';
import {
  Gender,
  Patient,
  PatientCondition,
  PatientType,
} from '../models/patients';

export interface PatientRequest {
  dispensaryId?: number;
  patientId?: number;
  dispensaryName?: string;
  patientFirstName?: string;
  patientLastName?: string;
  patientPhone?: string;
  patinetEmail?: string;
  getCount?: boolean;
  offset?: number;
  limit?: number;
  order?: string;
  orderBy?: string;
}

export interface PatientData {
  firstName?: string;
  lastName?: string;
  phone?: string;
  email?: string;
  caregiver?: string;
  mmccNumber?: string;
  mmccExpDate?: string;
  prescritionNumber?: string;
  certificateExpDate?: string;
  dob?: Date | undefined;
  gender?: Gender;
  patientType?: PatientType;
  dispensaryId: number;
  prescriptionNumber?: string;
}

export const createPatient = async (params: PatientData): Promise<any> => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${apiUrl}/dispensaries/${params.dispensaryId}/patients/`, params)
      .then((response) => {
        resolve(response.data.data);
      })
      .catch((error) => {
        if (error.response != undefined) {
          reject(error.response.data.msg);
        } else {
          reject(error.toString());
        }
      });
  });
};

export const searchPatients = async (params: PatientRequest): Promise<any> => {
  const res = await axios.get(`${apiUrl}/patients`, {
    params: {
      ...cleanDeep(params),
    },
  });

  return res.data;
};

export const updatePatient = async (
  dispensaryId: number,
  updatedPatient: Patient,
): Promise<any> => {
  try {
    const patient = {
      ...updatedPatient,
    };
    patient.dispensary = undefined;
    if (updatedPatient.phoneNumber) {
      patient.phoneNumber = `+${updatedPatient.phoneNumber.replace(/\D/g, '')}`;
    }

    const response = await axios.put(
      `${apiUrl}/dispensaries/${dispensaryId}/patients/${updatedPatient.id}?db=MySQL`,
      cleanDeep(patient),
    );

    return response.data;
  } catch (err) {
    throw err.message;
  }
};

export const deletePatient = async (
  dispensaryId: number,
  patientId: number,
) => {
  return new Promise<void>(async (resolve) => {
    await axios.delete(
      `${apiUrl}/dispensaries/${dispensaryId}/patients/${patientId}?db=mysql`,
    );
    resolve();
  });
};

export const fetchAllConditions = () => {
  return new Promise<Array<PatientCondition>>(async (resolve, reject) => {
    try {
      const res = await axios.get(`${apiUrl}/patients/conditions`);
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const fetchPatientPurchaseHistory = (patientId) => {
  return new Promise<any>(async (resolve, reject) => {
    try {
      const res = await axios.post(`${apiUrl}/reservations/salesinfo`, {
        patientId,
      });
      resolve(res.data);
    } catch (error) {
      reject(error);
    }
  });
};

export const addPatientCondition = async (
  patientCondition: string,
  patientId: number,
  caseId: number,
  conditionId: number,
) => {
  return new Promise<void>(async (resolve) => {
    const params = {
      conditionId,
      patientCondition,
      patientId,
      caseId,
      isActive: true,
    };
    await axios.post(`${apiUrl}/patients`, params);
    resolve();
  });
};

export const deletePatientCondition = async (
  conditionId: number,
  patientCondition: string,
  patientId: number,
  caseId: number,
  indexConditionId: number,
) => {
  return new Promise<void>(async (resolve) => {
    const params = {
      patientCondition,
      conditionId,
      patientId,
      caseId,
      isActive: false,
      indexConditionId,
    };
    await axios.post(`${apiUrl}/patients`, params);
    resolve();
  });
};

/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import Loading from './Loading';

const PublicRoute = ({ component: Component, ...rest }) => {
  const authContext: AuthContext = useContext(_authContext);
  const { loading } = authContext;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return <Loading />;
        }
        return <Component {...props} />;
      }}
    />
  );
};

export default PublicRoute;

import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tab, Tabs, Toast } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import MaskedInput from 'react-text-mask';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { Patient } from '../../models/patients';
import { formatPhoneNumber } from '../../models/common';
import { getValiadatedDate } from '../../models/utils';
import 'react-datepicker/dist/react-datepicker.css';
import { Case, InboundChannel } from '../../models/cases';
import { updatePatient } from '../../services/patient.service';
import '../../assets/css/call-detail.scss';
import '../../assets/css/patient-detail.scss';
import { Spinner } from 'react-bootstrap';
import { setTimeout } from 'timers';

type PatientInfoProps = {
  patient: Patient;
  case?: Case;
  onModified?: Function;
  onSave?: Function;
};

const PatientInfo = React.memo<PatientInfoProps>((props) => {
  const [showLoadspinner, setShowLoadspinner] = useState(false);
  const [mmccError, setMMCCerror] = useState('');
  const [showA, setShowA] = useState(false);
  const [showB, setShowB] = useState(false);
  const [contactError, setError] = useState();
  const [errorMsg, setErrorMsg] = useState('');
  const [mmccValid, setMMCCValid] = useState(false);

  const toggleShowA = () => {
    setShowA(!showA);
    setTimeout(() => setShowA(!showA), 5000);
  };

  const toggleShowB = (statusToDisplay = '') => {
    if (errorMsg) {
      if (statusToDisplay === '') {
        setShowB(!showB);
        setTimeout(() => setShowB(!showB), 5000);
      } else {
        setShowB(true);
        setTimeout(() => setShowB(false), 5000);
      }
    }
  };

  const [patient, setPatient] = useState<Patient>({
    id: 0,
    dispensaryId: 0,
    firstName: '',
    lastName: '',
    caregiver: '',
    emailAddress: '',
    phoneNumber: '',
    mmccNumber: '',
    mmccExpDate: undefined,
    certificateExpDate: undefined,
  });

  const {
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    mmccNumber,
    mmccExpDate,
    certificateExpDate,
  } = useMemo(
    () => ({
      ...patient,
      phoneNumber: patient.phoneNumber || '+1',
      dispensaryId: patient.dispensary ? patient.dispensary.id : 0,
      mmccExpDate: getValiadatedDate(patient.mmccExpDate, new Date()),
      certificateExpDate: getValiadatedDate(
        patient.certificateExpDate,
        undefined,
      ),
    }),
    [patient],
  );

  const isEmail = useMemo(
    () => props.case && props.case.inboundChannel === InboundChannel.EMAIL,
    [props.case],
  );

  const isPhone = useMemo(
    () => props.case && props.case.inboundChannel === InboundChannel.PHONE,
    [props.case],
  );

  useEffect(() => {
    if (props.patient) {
      setPatient(props.patient);
    }
  }, [props.patient]);

  const onChange = useCallback(
    (e) => {
      if (props.onModified) props.onModified();

      if ((e.target.name = 'mmccNumber')) {
        let vals = '';

        console.log(
          'Vals :',
          e.target.value.length,
          e.target.value.toString().toUpperCase(),
        );
        if (e.target.value.length > 0) {
          vals = e.target.value.toString().toUpperCase();
        }

        if (!new RegExp(/\w{4}-\w{4}-\w{4}-\w{4}/g).test(vals)) {
          setMMCCValid(false);
          setMMCCerror('');
        } else {
          setMMCCValid(true);
          console.log('Valie MMCC Number');

          // Loader will start spinning

          setShowLoadspinner(true);
          setTimeout(() => {
            setShowLoadspinner(false);
          }, 2000);

          if (vals == 'A8A8-A8A8-A8A8-A8A8') {
            setMMCCerror('Verified ✔️');
          } else if (vals == 'A8A8-A8A8-A8A8-A8A9') {
            setMMCCerror('METRC Servers are down');
          } else if (vals == 'A8A8-A8A8-A8A8-A8A7') {
            setMMCCerror('Expired ⚠️');
          } else if (vals == 'A8A8-A8A8-A8A8-A8A6') {
            setMMCCerror('Invalid ❌');
          }
        }

        setPatient({ ...patient, [e.target.name]: vals });
      }

      // TODO VALIDATE EMAIL AND PHONE
      setErrorMsg('');
      if (e.target.name === 'emailAddress') {
        if (
          !new RegExp(/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,15}/i).test(
            e.target.value,
          )
        ) {
          setErrorMsg('Invalid Email');
          toggleShowB();
        }
      } else if (e.target.name === 'phoneNumber') {
        console.log('Phone Number', e.target.value);
        if (
          new RegExp(
            /^((\+1|1)?( |-)?)?(\([2-9][0-9]{2}\)|[2-9][0-9]{2})( |-)?([2-9][0-9]{2}( |-)?[0-9]{4})$/,
          ).test(e.target.value)
        ) {
          setErrorMsg('Invalid Phone');
          toggleShowB();
        }
      }
    },
    [patient],
  );

  const onChangeCertDate = useCallback(
    (e) => setPatient({ ...patient, certificateExpDate: e }),
    [patient],
  );

  const onChangeMmccDate = useCallback(
    (e) => setPatient({ ...patient, mmccExpDate: e }),
    [patient],
  );

  const onUpdatePatient = useCallback(async () => {
    if (errorMsg) {
      toggleShowB('Display');
    } else {
      try {
        const res: any = await updatePatient(
          patient.dispensary ? patient.dispensary.id : 0,
          patient,
        );
        setShowA(!showA);
        setPatient(res);
        if (props.onSave) props.onSave();
      } catch (error) {
        setError(error);
      }
    }
  }, [patient, showA]); // eslint-disable-line react-hooks/exhaustive-deps

  const LoginLoader = () => (
    <button className="login-button" disabled>
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
      <Spinner
        as="span"
        animation="grow"
        size="sm"
        role="status"
        aria-hidden="true"
      />
    </button>
  );

  return (
    <Tabs defaultActiveKey="patient" id="call-details">
      <Tab eventKey="patient" title="Patient Details">
        <div className="outer-container">
          {contactError ? contactError : null}
          <Toast
            className="outer-container--toast"
            style={{ backgroundColor: 'green', color: 'white' }}
            show={showA}
            onClick={toggleShowA}
            onClose={toggleShowA}
            delay={1500}
            autohide
          >
            <Toast.Body>Patient Info Saved</Toast.Body>
          </Toast>

          <Toast
            className="outer-container--toast"
            style={{ backgroundColor: 'red', color: 'white' }}
            show={showB}
            onClick={() => toggleShowB()}
            onClose={() => toggleShowB()}
            delay={1500}
            autohide
          >
            <Toast.Body>{errorMsg}</Toast.Body>
          </Toast>

          <div className="patient-detail--inner">
            <button
              type="button"
              className="save-button"
              onClick={onUpdatePatient}
            >
              <FontAwesomeIcon icon={faSave} />
            </button>
            <div className="inner-container">
              <div className="left-style">
                <div className="name-inputs">
                  <div className="input-container">
                    First Name
                    <input
                      className="input-box"
                      type="text"
                      name="firstName"
                      value={firstName}
                      onChange={onChange}
                    />
                  </div>
                  <div className="input-container">
                    Last Name
                    <input
                      className="input-box"
                      type="text"
                      name="lastName"
                      value={lastName}
                      onChange={onChange}
                    />
                  </div>
                </div>
                <div className="input-container">
                  Email
                  <input
                    disabled={isEmail}
                    className={`input-box input-box-${isEmail}`}
                    type="email"
                    name="emailAddress"
                    value={emailAddress}
                    onChange={onChange}
                  />
                </div>
                <div className="input-container">
                  Telephone
                  <MaskedInput
                    mask={[
                      '+',
                      /[1-9]/,
                      ' ',
                      '(',
                      /[1-9]/,
                      /\d/,
                      /\d/,
                      ')',
                      ' ',
                      /\d/,
                      /\d/,
                      /\d/,
                      '-',
                      /\d/,
                      /\d/,
                      /\d/,
                      /\d/,
                    ]}
                    disabled={isPhone}
                    className={`input-box input-box-${isPhone}`}
                    type="text"
                    name="phoneNumber"
                    value={formatPhoneNumber(phoneNumber)}
                    onChange={onChange}
                  />
                </div>
              </div>
              <div className="right-style">
                <div>
                  <div className="input-container">
                    MMCC #
                    <input
                      // TODO disabling auto complete is not working
                      autoComplete="off"
                      className="input-box"
                      type="text"
                      name="mmccNumber"
                      value={mmccNumber}
                      onChange={onChange}
                      style={{
                        border: mmccValid
                          ? '1px solid blue'
                          : '1px solid orange',
                      }}
                    />
                  </div>
                  <span>{showLoadspinner ? <LoginLoader /> : mmccError}</span>
                </div>
                <div>
                  <div className="input-container input-container--date">
                    <p>MMCC # Exp Date</p>
                    <DatePicker
                      autoComplete="off"
                      selected={mmccExpDate}
                      onChange={onChangeMmccDate}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
                <div>
                  <div className="input-container input-container--date">
                    <p>Certificate Exp Date</p>
                    <DatePicker
                      autoComplete="off"
                      selected={certificateExpDate}
                      onChange={onChangeCertDate}
                      dateFormat="MM/dd/yyyy"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Tab>
    </Tabs>
  );
});

export default PatientInfo;

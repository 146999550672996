/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Route, Redirect } from 'react-router-dom';

import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';
import Loading from './Loading';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const authContext: AuthContext = useContext(_authContext);
  const { isAuthenticated, loading } = authContext;

  return (
    <Route
      {...rest}
      render={(props) => {
        if (loading) {
          return <Loading />;
        }
        if (!isAuthenticated) {
          return <Redirect to="/" />;
        }
        return <Component {...props} {...rest} />;
      }}
    />
  );
};

export default PrivateRoute;

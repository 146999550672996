export const GET_ME_SUCCESS = 'GET_ME_SUCCESS';
export const GET_ME_FAIL = 'GET_ME_FAIL';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const GET_CASES_SUCCESS = 'GET_CASES_SUCCESS';
export const GET_CASES_FAIL = 'GET_CASES_FAIL';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAIL = 'RESET_PASSWORD_FAIL';
export const RENDER_CALL = 'RENDER_CALL';
export const CONTACT_ERROR = 'CONTACT_ERROR';
export const SHOW_CALL = 'SHOW_CALL';
export const END_CONTACT = 'END_CONTACT';
// export const SET_CALL_DATA = 'SET_CALL_DATA';
export const UPDATE_CONTACT_CASE_PATIENT = 'UPDATE_CONTACT_CASE_PATIENT';
export const GET_PATIENTS_SUCCESS = 'GET_PATIENTS_SUCCESS';
export const GET_PATIENTS_FAIL = 'GET_PATIENTS_FAIL';
export const GET_DISPENSARIES_SUCCESS = 'GET_DISPENSARIES_SUCCESS';
export const GET_DISPENSARIES_FAIL = 'GET_DISPENSARIES_FAIL';
export const SET_CONTACT_CASE = 'SET_CONTACT_CASE';
export const CREATE_CASE_SUCCESS = 'CREATE_CASE_SUCCESS';
export const CREATE_CASE_FAIL = 'CREATE_CASE_FAIL';
export const GET_DISPENSARY_SUCCESS = 'GET_DISPENSARY_SUCCESS';
export const GET_DISPENSARY_FAIL = 'GET_DISPENSARIES_FAIL';
export const RENDER_EMAIL = 'RENDER_EMAIL';
export const SHOW_EMAIL = 'SHOW_EMAIL';
export const SEND_EMAIL = 'SEND_EMAIL';
export const MESSAGE = 'MESSAGE';
export const CLEAR_ERROR = 'CLEAR_ERROR';
export const GET_CASE = 'GET_CASE';
export const GET_DISPENSARY_CASES_SUCCESS = 'GET_DISPENSARY_CASES_SUCCESS';
export const GET_PATIENTS_BY_DISPENSARY = 'GET_PATIENTS_BY_DISPENSARY';
export const GET_TOPICS = 'GET_TOPICS';
export const GET_PATIENT_CASSES_SUCCESS = 'GET_PATIENT_CASSES_SUCCESS';
export const SAVE_CASE = 'SAVE_CASE';
export const CLEAR_CASES = 'CLEAR_CASES';
export const ON_GOING_CALL = "ON_GOING_CALL";
export const AGENT_STATUS = 'AGENT_STATUS';
export const DARK_THEME = 'DARK_THEME';
export const LIGHT_THEME = 'LIGHT_THEME';


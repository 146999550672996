import React, { useContext, useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';

const Loading = () => {
  const authContext: AuthContext = useContext(_authContext);
  const loadUser = authContext.loadUser || (() => { });

  useEffect(() => {
    loadUser();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="app-loading-container">
      <Spinner animation="border" />
    </div>
  );
};

export default Loading;

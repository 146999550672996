import React, { useReducer } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { Dispensary } from '../../models/dispensaries';
import { dispensaryContext as DispensaryContext } from './dispensaryContext';
import dispensaryReducer from './dispensaryReducer';
import {
  GET_DISPENSARIES_SUCCESS,
  GET_DISPENSARIES_FAIL,
  GET_DISPENSARY_SUCCESS,
  GET_DISPENSARY_FAIL,
} from '../types';

import { apiUrl } from '../../config/environments';

const DispensaryState = (props) => {
  const initialState = {
    dispensaries: null,
  };

  const [state, dispatch] = useReducer(dispensaryReducer, initialState);

  const getDispensaries = async () => {
    try {
      const res = await axios.get(`${apiUrl}/dispensaries?db=mysql`);
      const dispensaries: Dispensary[] = res.data;
      dispatch({
        type: GET_DISPENSARIES_SUCCESS,
        payload: dispensaries,
      });
    } catch (err) {
      dispatch({
        type: GET_DISPENSARIES_FAIL,
        payload: err.message,
      });
    }
  };

  const getDispensary = async (dispensaryId: number) => {
    try {
      const res = await axios.get(`${apiUrl}/dispensaries/${dispensaryId}?db=mysql`);
      const dispensary: Dispensary = res.data;
      dispatch({
        type: GET_DISPENSARY_SUCCESS,
        payload: dispensary,
      });
    } catch (err) {
      dispatch({
        type: GET_DISPENSARY_FAIL,
        payload: err.message,
      });
    }
  };

  return (
    <DispensaryContext.Provider
      value={{
        dispensaries: state.dispensaries,
        dispensary: state.dispensary,
        getDispensaries,
        getDispensary,
      }}
    >
      {props.children}
    </DispensaryContext.Provider>
  );
};

export default DispensaryState;

DispensaryState.propTypes = {
  children: PropTypes.shape({
  }),
};

DispensaryState.defaultProps = {
  children: {},
};

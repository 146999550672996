import { StringLiteralLike } from 'typescript';
import { Dispensary } from './dispensaries';

export interface PatientContext {
  patients?: Array<Patient>;
  getPatients?: Function;
  getPatientsByDispensary?: Function;
  deletePatient?: Function;
}

export interface PatientCreate {
  id?: number;
  phoneNumber?: string;
  dispensaryId: number;
}

export enum Gender {
  Male = 'Male',
  Female = 'Female',
  Other = 'Other',
}

export enum PatientType {
  CareGiver = 'caregiver',
  Patient = 'patient',
}

export interface Patient extends PatientCreate {
  id?: number;
  firstName?: string;
  lastName?: string;
  emailAddress?: string;
  phoneNumber?: string;
  caregiver?: string;
  gender?: Gender;
  patientType?: PatientType;
  priscriptionNumber?: string;
  mmccNumber?: string;
  caregivermmcc?: string;
  mmccExpDate?: Date;
  dob?: Date;
  certificateExpDate?: Date;
  dispensary?: Dispensary;
  caseCount?: number;
}

export interface PatientCondition {
  conditionId: number;
  conditionName: string;
  conditionType: string;
}

export interface PatientPut extends Patient {
  caseNumber: number;
}

import React, { useEffect, useState, useContext } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import {
  faChevronLeft,
  faChevronRight,
  faPhoneSlash,
  faPause,
  faTimes,
  faMicrophone,
  faMicrophoneSlash,
} from '@fortawesome/free-solid-svg-icons';
import { Spinner, Toast } from 'react-bootstrap';
import {
  ContactContext,
  AuthContext,
  formatPhoneNumber,
  SidePanelContext,
} from '../../models/common';
// import { Patient } from '../../models/patients';
import { getDispensaryColor } from '../../models/dispensaries';
import { getTimerFromSeconds } from '../../models/utils';
import { contactContext as _contactContext } from '../../context/contact/contactContext';
import { authContext as _authContext } from '../../context/auth/authContext';
import { sidePanelContext as _sidePanelContext } from '../../context/sidePanel/sidePanelContext';
import ContactDetail from '../Contact/ContactDetail';
import { ccpUrl } from '../../config/environments';
import { apiUrl } from '../../config/environments';
import { CaseStatus } from '../../models/cases';
import connect from '../../config/connect';
import { toast } from 'react-toastify';
// import 'amazon-connect-streams';

const inactiveButton = {
  borderRadius: '50%',
  fontSize: '1.5rem',
  lineHeight: '1.5rem',
  width: '3rem',
  height: '3rem',
  border: '1px solid',
  margin: '0px 2px',
} as React.CSSProperties;

const activeButton = {
  borderRadius: '50%',
  fontSize: '1.5rem',
  lineHeight: '1.5rem',
  width: '3rem',
  height: '3rem',
  margin: '0px 2px',
  border: '1px solid',
  color: '#111',
} as React.CSSProperties;

declare global {
  interface Window {
    myCPP: any;
  }
}

const SoftPhone = () => {
  const [agentStatus, setAgentStatus] = useState();
  const [agentName, setAgentName] = useState();
  const [agentProfile, setAgentProfile] = useState();
  const [contactState, setContactState] = useState();
  const [contactQueue, setContactQueue] = useState();
  const [showCallButtons, setShowCallButtons] = useState(false);
  const [holdState, setHoldState] = useState(false);
  const [muteState, setMuteState] = useState(false);
  const [callStartTime, setCallStartTime] = useState<number>(0);
  const [callDuration, setDuration] = useState<number>(0);
  const [callDurationLogged, setDurationLogged] = useState<boolean>(false);
  const [localShowCall, setLocalShowCall] = useState(true);
  const [showContactDetails, setLocalShowContactDetails] = useState(true);
  const [callCase, setCallCase] = useState();
  const authContext: AuthContext = useContext(_authContext);
  const sidePanelContext: SidePanelContext = useContext(_sidePanelContext);
  const agentStatusToggle = sidePanelContext.agentStatusToggle || (() => {});
  const { user } = authContext;
  const [showC, setShowC] = useState(false);
  const [isCallForwarding, setForwarding] = useState(false);

  const contactContext: ContactContext = useContext(_contactContext);
  const onGoingCallToggle = contactContext.onGoingCallToggle!;
  const initCall = contactContext.initCall!;
  const { showCall, contactCase, onGoingCall } = contactContext;

  const logIncomingCallCase = async () => {
    const url = `${apiUrl}/dispensaries/${callCase!.dispensary.id}/cases/${
      callCase!.caseNumber
    }`;

    await axios.post(`${url}/logs`, {
      note: `Call from ${
        callCase!.patient.firstName
          ? `${callCase!.patient.firstName} ${callCase!.patient.lastName}`
          : formatPhoneNumber(callCase!.patient.phoneNumber)
      } picked up by ${user!.groups[0]}`,
      status: 'IN_PROGRESS',
      skill: callCase!.skill,
      logType: 'SYSTEM',
    });
    initCall(callCase, user, 'inbound');
  };

  const logEndedCallCase = async () => {
    console.log('Log End Called');
    const duration = callDuration;
    setDuration(0);
    setCallStartTime(0);
    setShowCallButtons(false);
    console.log('On Call Ended :@############ : ', callCase);
    // const url =
    //   !!callCase && !!callCase.dispensary
    //     ? `${apiUrl}/dispensaries/${callCase!.dispensary.id}/cases/${
    //         callCase!.caseNumber
    //       }`
    //     : '';

    // await axios.post(`${url}/logs`, {
    //   note: `Call with ${
    //     callCase!.patient.firstName
    //       ? `${callCase!.patient.firstName} ${callCase!.patient.lastName}`
    //       : formatPhoneNumber(callCase!.patient.phoneNumber)
    //   } ended by/from ${user!.groups[0]}`,
    //   duration: duration,
    //   skill: callCase!.skill,
    //   logType: 'SYSTEM',
    // });
  };

  useEffect(() => {
    if (showCallButtons)
      setTimeout(() => {
        setDuration(Math.floor(new Date().getTime() / 1000) - callStartTime);
      }, 1000);
    else {
      console.log('Call Duration : ', callDuration, callDurationLogged);
      if (!callDurationLogged && callDuration > 0) {
        setDurationLogged(true);
        logEndedCallCase();
        console.log(callDuration > 0);
      }
    }
  }, [callStartTime, showCallButtons, callDuration, callDurationLogged]);

  const showCloseToast = () => {
    setShowC(true);
    setTimeout(() => {
      setShowC(false);
    }, 3000);
  };

  const closePanel = () => {
    if (
      !onGoingCall &&
      (callCase.status !== CaseStatus.IN_PROGRESS &&
        callCase.status !== CaseStatus.PENDING)
    ) {
      setLocalShowCall(!localShowCall);
    } else {
      showCloseToast();
    }
  };

  const localShowCheck = () => {
    if (
      !onGoingCall &&
      (callCase!.status !== CaseStatus.IN_PROGRESS &&
        callCase!.status !== CaseStatus.PENDING)
    ) {
      setLocalShowContactDetails(!showContactDetails);
    } else {
      showCloseToast();
    }
  };
  useEffect(() => {
    if (
      agentStatus == 'Busy' ||
      agentStatus == 'Connecting' ||
      agentStatus == 'Connected' ||
      agentStatus == 'PendingBusy'
    ) {
      onGoingCallToggle(true);
    } else {
      onGoingCallToggle(false);
    }
  }, [agentStatus, onGoingCall]);

  useEffect(() => {
    if (callCase) logIncomingCallCase();
  }, [callCase]);

  useEffect(() => {
    window.myCPP = window.myCPP || {};
    window.onpopstate = function(event) {
      // alert(
      //   'location: ' +
      //     document.location +
      //     ', state: ' +
      //     JSON.stringify(event.state),
      // );
    };

    window.onbeforeunload = function(e) {
      return 'Dialog text here.';
    };

    const connectDiv = document.getElementById('connectDiv');

    connect.core.initCCP(connectDiv, {
      ccpUrl: ccpUrl,
      loginPopup: true,
      softphone: {
        allowFramedSoftphone: true,
      },
    });

    const subscribeToAgentEvents = (agent) => {
      const w = window.open('', connect.MasterTopics.LOGIN_POPUP);
      if (w) {
        w.close();
      }
      window.myCPP.agent = agent;
      const conf = agent.getConfiguration();
      setAgentName(conf.username);
      setAgentProfile(conf.routingProfile.name);

      agent.onRefresh((a) => {
        // console.log("On refresh",a , a.getStatus());
        agentStatusToggle(a.getStatus().type, a.getStatus().name);
        setAgentStatus(a.getStatus().name);
      });
      agent.onRoutable((a) => {
        // console.log("On Routable",a , a.getStatus());
        agentStatusToggle(a.getStatus().type, a.getStatus().name);
        setAgentStatus(a.getStatus().name);
      });
      agent.onNotRoutable((a) => {
        // console.log("On Not Routable",a , a.getStatus());
        agentStatusToggle(a.getStatus().type, a.getStatus().name);
        setAgentStatus(a.getStatus().name);
      });
      agent.onOffline((a) => {
        // console.log("On Offline",a , a.getStatus());
        agentStatusToggle(a.getStatus().type, a.getStatus().name);
        setShowCallButtons(false);
        setAgentStatus(a.getStatus().name);
      });
      agent.onMuteToggle((a) => {
        setMuteState(a.muted);
      });
    };

    const subscribeToContactEvents = (contact) => {
      window.myCPP.contact = contact;

      setContactQueue(contact.getQueue().name);

      /*
      // We have access to the contact/call
      // Information coming from Connect here
      // It includes the contact/call's associated case
      // const attributes = contact.getAttributes();
      // const call = JSON.parse(attributes.CaseJSON.value);
      */

      contact.onIncoming((c) => {
        setContactState(c.getStatus().type);
      });

      contact.onConnecting((c) => {
        if (!contact.isInbound()) {
          setShowCallButtons(true);
        }
        setContactState(c.getStatus().type);
      });

      /* Current code
      contact.onConnecting((c) => {
        console.debug("On Connecting :: ", c.getStatus().type, c); 
        const attributes = contact.getAttributes();
        setCallCase(JSON.parse(attributes.CaseJSON.value));
        setShowCallButtons(true);
        setContactState(c.getStatus().type)
      });
*/

      contact.onAccepted((c) => {
        console.debug('On Accepted', c.getStatus().type, c);
        setContactState(c.getStatus().type);
      });

      contact.onConnected((c) => {
        setCallStartTime(Math.floor(new Date().getTime() / 1000));
        setShowCallButtons(true);
        setContactState(c.getStatus().type);
        const connections = window.myCPP.contact.getConnections();
        const incomingEndpoint = connections[1].getEndpoint();
        const attributes = contact.getAttributes();
        //Add condition for outbound call
        if (
          contact.isInbound() &&
          attributes &&
          attributes.CaseJSON &&
          attributes.CaseJSON.value
        ) {
          setCallCase(JSON.parse(attributes.CaseJSON.value));
        }
      });

      /* Current code
      contact.onConnected((c) => {
        console.debug("On Connected", c);
        setShowCallButtons(true);
        setContactState(c.getStatus().type);
        const connections = window.myCPP.contact.getConnections();
        const incomingEndpoint = connections[1].getEndpoint();
        if(connections.isOnHold()){
          setHoldState(true);
          console.debug("ON HOLD",connections.isOnHold());
        }else{
          console.debug("NOT ON HOLD",connections.isOnHold())
        }
      });
*/

      contact.onEnded((c) => {
        console.debug('On Ended', c);
        const offlineState = window.myCPP.agent
          .getAgentStates()
          .filter((state) => state.type === connect.AgentStateType.OFFLINE)[0];
        setContactState(c.getStatus().type);
        window.myCPP.agent.setState(offlineState);
        onGoingCallToggle(false);
      });
    };

    connect.contact(subscribeToContactEvents);
    connect.agent(subscribeToAgentEvents);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleHold = () => {
    const conn = window.myCPP.contact.getConnections()[1];
    if (!holdState) {
      conn.hold({
        success: function() {
          setHoldState(true);
        },
        failure: function(err) {},
      });
    } else {
      conn.resume({
        success: function() {
          setHoldState(false);
        },
        failure: function(err) {},
      });
    }
  };

  const toggleMute = () => {
    const agent = window.myCPP.agent;
    console.log('Agent', agent);
    if (muteState) agent.unmute();
    else agent.mute();
  };

  const hangUpCall = () => {
    window.myCPP.contact.getAgentConnection().destroy();
  };

  const handleForward = () => {
    setForwarding(true);
    window.myCPP.contact.addConnection(
      connect.Endpoint.byPhoneNumber('+12029223633'),
      {
        success: () => {
          toast('Call forwarded successfully !');
        },
        failure: (e) => {
          toast('Error forwarding call !');
        },
      },
    );
  };

  useEffect(() => {
    if (showCall) {
      setLocalShowCall(true);
      // setPatient({ ...contactCase!.patient });
      setTimeout(() => setLocalShowContactDetails(true), 2000);
      if (window.myCPP.agent) {
        const routableState = window.myCPP.agent
          .getAgentStates()
          .filter((state) => state.type === connect.AgentStateType.ROUTABLE)[0];
        window.myCPP.agent.setState(routableState);
      }
    } else {
      setLocalShowContactDetails(false);
      setLocalShowCall(false);
    }
  }, [showCall]);

  let holderLogo = (
    <img
      className="in-phone-logo"
      src={contactCase && contactCase.dispensary.companyLogoUrl}
      alt="company logo"
    />
  );
  if (contactCase && contactCase.dispensary.name === 'Curaleaf') {
    holderLogo = (
      <img
        className="in-phone-logo"
        src="https://upload.wikimedia.org/wikipedia/en/thumb/d/d9/Curaleaf_logo.png/220px-Curaleaf_logo.png"
        alt="company logo"
      />
    );
  }

  return (
    <div>
      <div id="connectDiv" />
      {localShowCall ? (
        <>
          <div className="connect-div--show-call">
            <ContactDetail showContactDetails={showContactDetails} />

            <button
              type="button"
              className="toggle-contact-detail-button"
              onClick={localShowCheck}
            >
              {showContactDetails ? (
                <FontAwesomeIcon icon={faChevronRight} />
              ) : (
                <FontAwesomeIcon icon={faChevronLeft} />
              )}
            </button>
            {showCallButtons ? (
              <button className="call-forward-button" onClick={handleForward}>
                {isCallForwarding
                  ? 'Forwarding call to phone...'
                  : 'Forward to Phone'}
              </button>
            ) : null}
            <button
              type="button"
              className="toggle-contact-detail-button1"
              onClick={closePanel}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
            <Toast
              className="outer-container--toast"
              style={{ backgroundColor: 'orange', color: 'white' }}
              show={showC}
              delay={2000}
              autohide
            >
              <Toast.Body>
                Cant close panel if the case is still open.
              </Toast.Body>
            </Toast>
            <div>
              {contactCase ? (
                <div>
                  <div className="contact-case">
                    {holderLogo}
                    <div
                      className={`dispensary-name-tag background-color-${
                        contactCase
                          ? getDispensaryColor(contactCase.dispensary)
                          : '#000'
                      }`}
                    >
                      {contactCase!.dispensary.name}
                    </div>
                  </div>
                  <div className="contact-case-patient-name">
                    {contactCase.patient
                      ? `${contactCase.patient.firstName || ''} ${contactCase
                          .patient.lastName || ''}`
                      : ''}
                  </div>
                  <div className="contact-case-patient-number">
                    {formatPhoneNumber(contactCase.inboundPhoneNumber)}
                  </div>
                </div>
              ) : null}

              <div className="call-buttons">
                <button
                  type="button"
                  style={holdState ? activeButton : inactiveButton}
                  onClick={() => toggleHold()}
                >
                  <FontAwesomeIcon icon={faPause} />
                </button>
                {['CallingCustomer', 'PendingBusy'].includes(agentStatus) &&
                !showCallButtons ? (
                  <div className="calling-customer">
                    <Spinner
                      className="calling-customer--spinner"
                      animation="grow"
                    />
                  </div>
                ) : null}
                {showCallButtons ? (
                  <button
                    type="button"
                    className="end-call-button"
                    onClick={() => hangUpCall()}
                  >
                    <FontAwesomeIcon icon={faPhoneSlash} />
                  </button>
                ) : null}
                {!showCallButtons &&
                !['CallingCustomer', 'PendingBusy'].includes(agentStatus) ? (
                  <div />
                ) : null}
                <button
                  type="button"
                  onClick={() => toggleMute()}
                  style={muteState ? activeButton : inactiveButton}
                >
                  <FontAwesomeIcon
                    icon={muteState ? faMicrophoneSlash : faMicrophone}
                  />
                </button>
              </div>
              <div
                className="call-timer"
                style={{ display: callStartTime ? 'block' : 'none' }}
              >
                {getTimerFromSeconds(callDuration)}
              </div>
              <div className="call-info">
                <span>Agent User:</span>
                <span>{agentName}</span>
                <span>Agent Profile:</span>
                <span>{agentProfile}</span>
                <span>Agent Status:</span>
                <span>{agentStatus}</span>
              </div>

              {contactState ? (
                <div className="contact-state">
                  <div className="call-info">
                    <span>Contact State:</span>
                    <span className="capital-text">{contactState}</span>
                    <span>Contact Queue:</span>
                    <span>{contactQueue}</span>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          <div className="contact-details-mobile">
            <ContactDetail showContactDetails={showContactDetails} />
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SoftPhone;


import React, { useContext } from 'react';

import { AuthContext } from '../../models/common';
import { authContext as _authContext } from '../../context/auth/authContext';


const Messages = () => {
  const authContext: AuthContext = useContext(_authContext);
  const { messages } = authContext;

  return (
    messages && messages.length ? (
      <div className="messages">
        {
          <div />
          // TODO
          // messages.map((message) => (
          //   <Toast key={`${message}`} delay={3000} autohide onClose={() => { }}>
          //     <Toast.Header>
          //       <img
          //         src="holder.js/20x20?text=%20"
          //         className="rounded mr-2"
          //         alt=""
          //       />
          //       <strong className="mr-auto">Error</strong>
          //       <small>1 min ago</small>
          //     </Toast.Header>
          //     <Toast.Body>{message}</Toast.Body>
          //   </Toast>
          // ))
        }
      </div>
    ) : (null)
  );
};

export default Messages;
